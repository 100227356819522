import {ActionTree, MutationTree} from 'vuex'
import {RootState} from '@/store'
import {envInstance} from '@/lib/plugins/env'
import {log} from '@/lib/plugins/logger'

export interface TopbarButton {
  icon?: string
  action?: () => any
  component?: any
  props?: any
  text?: string
}

export class TopbarState {
  public topbarActions: TopbarButton[] = []
  public topBarTitle: string | null = envInstance.PAGE_TITLE || null
  public topBarHidden: boolean = false
}

const mutations: MutationTree<TopbarState> = {
  SET_ACTION_BUTTONS(actionButtons: TopbarState, payload: TopbarButton[]) {
    if (actionButtons.topbarActions === undefined) {
      Object.assign(TopbarState, {topbarActions: []})
    }
    payload.forEach((button) => {
      let duplicate = false
      if (actionButtons.topbarActions.length) {
        actionButtons.topbarActions.forEach((actionButton) => {
          const sameIcon = button.icon ? button.icon === actionButton.icon : false
          const sameComponent = button.component ? button.component === actionButton.component : false
          const sameText = button.text ? button.text === actionButton.text : false
          duplicate = sameIcon || sameComponent || sameText ? true : duplicate
        })
        if (!duplicate) {
          actionButtons.topbarActions.push(button)
        }
      } else {
        actionButtons.topbarActions.push(button)
      }
    })

  },
  FLUSH_ACTION_BUTTONS(actionButtons) {
    actionButtons.topbarActions = []
  },
  SET_TOPBAR_TITLE(state, t: string | null) {
    state.topBarTitle = t
  },
  SET_TOPBAR_HIDDEN(state, v: boolean) {
    state.topBarHidden = v
  },
}

const actions: ActionTree<TopbarState, RootState> = {
  setActionButtons({commit}, payload: TopbarButton[]) {
    commit('SET_ACTION_BUTTONS', payload)
  },
  flushActionButtons({commit}) {
    commit('FLUSH_ACTION_BUTTONS')
  },
  setTopbarTitle({commit}, t: string | null) {
    log('topbar title | ' + t, 0)
    commit('SET_TOPBAR_TITLE', t ?? null)
  },
  setTopbarHidden({commit}, v: boolean) {
    log(v, 0)
    commit('SET_TOPBAR_HIDDEN', v)
  },
}

export default {
  state: new TopbarState(),
  mutations,
  actions,
}
