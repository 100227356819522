























import {Component, Vue} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {Deposit} from '@/lib/kepler/interfaces'
import moment from 'moment'

import IsLogged from '../IsLogged.vue'
import Container from '../proxy/Container.vue'
import Layout from '../proxy/Layout.vue'
import GradientCard from '../GradientCard.vue'
import MonthList from '../MonthList.vue'
import CardButton from '../CardButton.vue'
import Button from '../Button.vue'
import Flex from '../proxy/Flex.vue'
import ConfirmDialogCallback from '../../views/ConfirmDialogCallback.vue'

@Component({
  name: 'Deposits',
  components: {
    ConfirmDialogCallback,
    IsLogged,
    Flex,
    Button,
    CardButton,
    Container,
    Layout,
    GradientCard,
    MonthList,
  },
})
export default class Deposits extends Vue {
  @State((state) => state.wallet.deposits) public depositState!: any
  @State((state) => state.wallet.dateDeposits) public dateDeposits!: any

  @Action('getDeposits') public getDeposits: any
  @Action('setDateDeposits') public setDateDeposits: any

  // in case we'll need to select a wallet
  // @Prop({
  // }) private wallets?: Array

  private selectedDate?: string | null = null

  private currentList?: Deposit[] = []

  private delayId?: any = undefined

  private month: string = moment().locale('en').format('YYYY-MM')

  private listLoading: boolean = false
  private paymentInProgress: boolean = false

  private statusClass(deposit: Deposit) {
    return {
      // TODO: get other statuses and their meaning
      'error--text': (deposit.status === 'FAIL'),
      'success--text': (deposit.status === 'NEW'),
    }
  }

  private setDelay(month: string) {
    if (typeof this.delayId === 'number') {
      this.clearDelay()
    }
    const that = this
    this.delayId = setTimeout(() => {
      that.getDeposits(month)
    }, 500)
  }

  private clearDelay() {
    clearTimeout(this.delayId)
    this.delayId = undefined
  }

  private changedMonth(month: string) {
    this.listLoading = true
    this.currentList = []
    if (this.depositsEmpty) {
      this.getDeposits(month).then(() => {
          this.currentList = this.depositState[month]
          this.setDateDeposits(month)
          this.listLoading = false
        },
      )
    } else {
      // if there is no data available
      if (this.depositState[month] === undefined) {
        // then get the list
        this.getDeposits(month).then(() => {
          this.currentList = this.depositState[month]
          this.setDateDeposits(month)
          this.listLoading = false
        })
        // if there is data available
      } else {
        // get list from local storage
        this.currentList = this.depositState[month]
        this.setDateDeposits(month)
        this.setDelay(month)
        this.listLoading = false
      }
    }
  }

  private formatDate(date: string) {
    return moment(date).format('LL')
  }

  private get monthList() {
    const current = this.currentList
    const selected = this.selectedDate
    const month = this.month

    if (this.depositState) {
      if (current) {
        return current
      } else if (selected && this.depositState.hasOwnProperty(selected)) {
        return this.depositState[selected]
      } else if (month && this.depositState.hasOwnProperty(month)) {
        return this.depositState[month]
      }
    }
  }

  private mounted() {
    if (this.depositsEmpty) {
      this.getDeposits(this.month)
    }
    if (!this.dateDeposits) {
      this.setDateDeposits(this.month)
      this.selectedDate = this.month
    } else {
      this.getDeposits(this.dateDeposits).then(() => {
          if (this.dateDeposits !== undefined) {
            this.currentList = this.depositState[this.dateDeposits]
          }
          this.listLoading = false
        },
      )
      this.selectedDate = this.dateDeposits
    }
  }

  private get depositsEmpty() {
    return !this.depositState || Object.entries(this.depositState).length === 0 && this.depositState.constructor === Object
  }
}
