import {ActionTree, GetterTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {DriversByPlan, Rate, RatesForCategoryRequest, SubscriptionPlan} from '@/lib/kepler/interfaces'
import {RootState, RS} from '@/store'
import {AxiosResponse} from 'axios'

export class SubscriptionsState {
  public plans: SubscriptionPlan[] | null = null
  public hasQueriedPlans: boolean | null = null
}

const mutations: MutationTree<SubscriptionsState> = {
  PLANS(stateS, payload) {
    stateS.plans = payload
    stateS.hasQueriedPlans = true
  },
  RATES(stateS, payload) {
    // please kill me
    if (stateS.plans !== null) {
      const planKey = stateS.plans.findIndex((obj: SubscriptionPlan) => obj.id === payload.id)
      const plan: SubscriptionPlan = stateS.plans[planKey]
      const rates0: Rate[] = plan.rates
      const rates1: Rate[] = payload.data
      const rates2: any[] = []
      rates0.forEach((itm: Rate, i: number) => {
        rates2.push(Object.assign({}, itm, rates1[i]))
      })
      stateS.plans[planKey].rates = rates2
    }
  },
  PURGE_PLANS(stateS) {
    stateS.plans = []
    stateS.hasQueriedPlans = false
  },
}

const actions: ActionTree<SubscriptionsState, RootState> = {
  purge({commit}) {
    commit('PURGE_PLANS')
  },
  getRates({commit, dispatch}, planId) {
    return sdk.subscription.rates(planId)
      .then((r: AxiosResponse<Rate[]>) => {
        commit('RATES', {data: r.data, id: planId})
        dispatch('sleep', 'rates')
        return r.data
      })
  },
  getPlans({commit, dispatch}): Promise<SubscriptionPlan[]> {
    return new Promise((resolve) => {
      const action = sdk.people.isLogged() ? sdk.subscription.availablePlans : sdk.subscription.publicPlans
      action()
        .then((r: AxiosResponse<SubscriptionPlan[]>) => {
          commit('PLANS', r.data)
          dispatch('sleep', 'plans')
          resolve(r.data)
        })
    })
  },
  getRatesForCategory({dispatch}, payload: RatesForCategoryRequest) {
    return dispatch('getRates', payload.planId).then((data: Rate[]) => {
      return data.filter((item) => item.vehicle_category.id === payload.categoryId)[0]
    })
  },
}

const getters: GetterTree<SubscriptionsState, RootState> = {
  defaultPlan: (s, g) => s.plans?.find((p) => p.id === g.defaultPlanId) || null,
  availablePlans: (s, g) => {
    if (s.plans?.length) {
      return s.plans.filter((p) => {
        const available = p.status === 'AVAILABLE'
        const valid = (p.valid_to_timestamp === null) || (p.valid_to_timestamp > Math.floor(Date.now() / 1000))
        return available && valid && p.id !== g.defaultPlan?.id
      })
    }
    return []
  },
  onlyDefaultPlan: (s, g) => g.defaultPlan && !g.availablePlans,
  driversByPlan: (s, g, r) => {
    const result: DriversByPlan = {}
    const profileState = (r as RS).profile
    profileState.subscriptions.forEach((sub) => {
      const dr = sub.drivers.map((d) => d.id)
      if (dr.length) {
        result[sub.plan.id] = dr
      }
    })
    const drivers = (r as RS).profile.drivers.map((d) => d.id)
    // flatten result, filter by unique values
    const assigned = [...new Set(Object.values(result).flat())]
    const unassigned = drivers.filter((d) => !assigned.includes(d))
    if (g.defaultPlan?.id) {
      result[g.defaultPlan.id] = unassigned
    } else {
      result.unassigned = unassigned
    }
    return result
  },
}

export default {
  state: new SubscriptionsState(),
  mutations,
  actions,
  getters,
}
