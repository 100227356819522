


















import {Component, Prop, Vue} from 'vue-property-decorator'

import {VCheckbox} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'
import Button from '@/components/Button.vue'
import sdk from '@/lib/kepler/sdk'
import Input from '@/components/proxy/Inputs/Input.vue'
import {ProviderInstance} from 'vee-validate/dist/types/types'
import CheckboxFieldWithValidation from '@/components/fields/CheckboxFieldWithValidation.vue'
import CheckBox from '@/components/proxy/Inputs/CheckBox.vue'
import Layout from '@/components/proxy/Layout.vue'
import {Action} from 'vuex-class'
import {FlowOutputRequest} from '@/store/modules/flowOutputs'

interface KycResponse {
  completed: boolean | number
  message: string
  can_retry: boolean
  retry_after?: number
}

@Component({
  components: {Layout, CheckBox, CheckboxFieldWithValidation, Input, Button, VCheckbox, ValidationProvider},
  name: 'VerificationField',
})
export default class VerificationField extends Vue {
  @Action('sendFlowOutput') public send!: (req: FlowOutputRequest) => Promise<any>

  @Prop() public readonly value?: boolean | null
  @Prop({type: Boolean, default: false}) public readonly disabled!: boolean
  @Prop({
    type: Object,
    default: () => new Object({provider: undefined}),
  }) public readonly options!: { provider?: string, url?: string, check_url?: string, delay?: number }
  @Prop({
    type: Boolean,
    default: '',
  }) public readonly required!: boolean
  @Prop({
    type: String,
    required: false,
  }) public name?: string
  @Prop({
    type: String,
    required: false,
  }) public description?: string

  public loading: boolean = false
  public canRetry: boolean = false
  public message: string = ''
  public providerInstance: ProviderInstance | null = null
  public checkTimeout: NodeJS.Timeout | null = null
  public t0: any = null

  public get innerValue() {
    return this.value ?? null
  }

  public set innerValue(v: boolean | null) {
    this.providerInstance?.validate(v)
    this.$emit('input', v)
  }

  protected get classes(): Record<string, boolean> {
    return this.providerInstance?.classes || {}
  }

  protected verify() {
    this.loading = true
    const provider = this.options.provider
    this.providerInstance?.reset()
    sdk.profile.get_verification(provider).then(({data}) => {
      if (data.provider === 'veriff' && 'Veriff' in window) {
        const w = window as Window & typeof globalThis & { Veriff?: any }
        w.Veriff?.run(
          data.payload.url,
        ).then(() => {
          this.innerValue = true
        }).catch(() => {
          this.innerValue = false
        }).finally(() => {
          this.loading = false
        })
      } else if (data.payload.url) {
        cordova.InAppBrowser.open(data.payload.url, '_system')
        if (this.options.check_url) {
          this.innerValue = null
          this.message = ''
          document.removeEventListener('visibilitychange', this.onVisibilityChange)
          document.addEventListener('visibilitychange', this.onVisibilityChange)
        } else {
          this.loading = false
          this.innerValue = true
        }
      } else {
        this.$log(`verification provider ${data.provider} is not implemented`, 3)
        this.innerValue = false
        this.loading = false
      }
    })
  }

  protected onVisibilityChange() {
    if (this.options.check_url && document.visibilityState === 'visible' && !this.checkTimeout) {
      this.t0 = performance.now()
      this.loading = true
      this.check(this.options.check_url, this.options.delay ?? 0)
    } else {
      this.clearTimeout()
    }
  }

  protected check(url: string, delay: number) {
    this.loading = true
    this.checkTimeout = setTimeout(() => {
      this.t0 = performance.now()
      sdk.flows.send(url, null)
        .finally(() => {
          this.clearTimeout()
        })
        .then((r) => {
          const data = r.data as KycResponse
          if (data.retry_after && !data.completed) {
            this.check(url, data.retry_after)
          } else {
            this.loading = false
            this.clearTimeout()
          }
          this.innerValue = !!data.completed
          this.message = data.message ?? ''
          this.canRetry = data.can_retry ?? false
        })
    }, delay * 1000)
  }

  protected clearTimeout() {
    clearTimeout(this.checkTimeout ?? undefined)
  }

  protected mounted() {
    this.providerInstance = this.$refs.provider as ProviderInstance
  }

  protected beforeDestroy() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  }
}
