














































import {Vue, Component} from 'vue-property-decorator'
import Utils from '@/utils'
import {Getter, State} from 'vuex-class'
import Button from '@/components/Button.vue'
import Icon from '@/components/proxy/Icon.vue'

@Component({
  components: {
    Icon,
    Button,
    NEOMWideButton: Utils.loadComponent('NEOMWideButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Container: Utils.loadComponent('proxy/Container'),
  },
})

export default class CustomHome extends Vue {
  @State((state) => state.configuration.appConfig?.persistent_help) public persistentHelp?: string
  @Getter('maintenance') public isMaintenance!: boolean

  protected get cardWidth() {
    return Math.min(343, this.$vuetify.breakpoint.width - 32)
  }

  protected action(num: number) {
    switch (num) {
      case 1:
        this.$router.push({name: 'bookingRequest'})
        break
      case 2:
        this.$router.push({path: '/activities/booking/kickscooter/ff'})
        break
      case 3:
        this.$router.push({name: 'bus'})
        break
      case 4:
        this.$router.push({name: 'map', params: {showEvCharge: 'true'}})
        break
      case 5:
        cordova.InAppBrowser.open('tel:' + this.persistentHelp, '_system')
        break
      case 6:
        this.$router.push({name: 'maintenance'})
    }
  }

  protected updated() {
    if (!this.$isLogged()) {
      this.$router.replace({name: 'login'})
    }
  }
}
