import {RootState} from '@/store'
import {ActionTree, GetterTree, MutationTree} from 'vuex'
import {Dialog} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

export const DialogState: Dialog[] = []

const mutations: MutationTree<typeof DialogState> = {
  INIT_DIALOG_STATE(state) {
    state = []
  },
  OPEN_DIALOG(state, payload: Dialog) {
    // check if dialog is identical to last one
    if (!state.find((d) => d.id === payload.id)) {
      state.push(payload)
    }
  },
  CLOSE_DIALOG(state, idx?: number | string) {
    let index = -1
    if (typeof idx === 'string') {
      index = state.findIndex((p) => p.id === idx)
    } else if (typeof idx === 'number') {
      index = idx
    }
    index >= 0 ? state.splice(index, 1) : state.pop()
  },
  FLUSH_DIALOG_QUEUE(state) {
    Utils.flushArray(state)
    state = []
  },
}

const actions: ActionTree<typeof DialogState, RootState> = {
  init({commit}) {
    commit('INIT_DIALOG_STATE')
  },
  openDialog({commit}, dialog: Dialog) {
    commit('OPEN_DIALOG', dialog)
    return dialog.id
  },
  closeDialog({commit}, idx?: number | string) {
    commit('CLOSE_DIALOG', idx)
  },
  closeOverlays({commit}) {
    commit('FLUSH_DIALOG_QUEUE')
  },
  flushConnectionErrors({state, commit}) {
    // more than a bit hacky
    for (const [i, d] of state.entries()) {
      if (d.dialogProps.title === 'common.you-are-offline') {
        commit('CLOSE_DIALOG', i)
      }
    }
  },
}

const getters: GetterTree<typeof DialogState, RootState> = {
  dialogIsOpen: (dialogs) => !!dialogs.length,
}

export default {
  state: DialogState,
  mutations,
  actions,
  getters,
}
