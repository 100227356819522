














import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'

import {WalletState} from '@/store/modules/wallet'
import {AppConfigState} from '@/store/modules/configuration'

import Utils from '@/utils'
import moment from 'moment'
import OverviewWallet from '@/components/wallet/OverviewWallet.vue'
import {RecordRequest, Wallet, WalletRecord} from '@/lib/kepler/interfaces'

@Component({
  name: 'Overview',
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    CardWithReport: Utils.loadComponent('CardWithReport'),
    OverviewWallet: Utils.loadComponent('OverviewWallet'),
  },
})
export default class Overview extends Vue {
  @State('wallet') public walletState!: WalletState
  @State('configuration') public configuration!: AppConfigState
  @Getter('defaultWallet') public defaultWallet?: Wallet
  @Action('getWallets') public getWallets!: () => Promise<Wallet[]>
  @Action('setDateOverview') public setDate!: (date: string) => void
  @Action('setDateInvoices') public setDateInvoices!: (date?: string) => void
  @Action('getRecords') public getRecords!: (req: RecordRequest) => Promise<[WalletRecord]>

  // @Prop({
  // }) private wallets?: Array
  protected selectedDate: string | null = null

  protected currentMonth = moment().locale('en').format('YYYY-MM')

  protected get wallet() {
    return this.defaultWallet
  }

  protected pos(n: number) {
    return !(n < 0)
  }

  protected formatDate(date: string) {
    return moment(date).format('LL')
  }

  protected created() {
    const m = this.currentMonth
    if (this.walletState.dateOverview == null || this.walletState.dateInvoices == null) {
      this.setDate(m)
      this.setDateInvoices(m)
      this.selectedDate = m
    } else {
      this.selectedDate = this.walletState.dateOverview
    }
    if (!this.walletState.wallets) {
      this.getWallets()
    }
  }

  protected mounted() {
    const id = this.wallet?.id
    const month = this.currentMonth
    if (id) {
      this.getRecords({id, account_type: 'credit', month})
      this.getRecords({id, account_type: 'debit', month})
    }

    // this.getRecords({id: this.wallet.id, account_type: 'credit', month: thisMonth})

    // if (this.walletState.dateOverview == null) {
    //   this.setDate(thisMonth)
    //   this.selectedDate = thisMonth
    // } else {
    //   this.selectedDate = this.walletState.dateOverview
    // }
    // if (this.walletState.wallets == null) {
    //   this.getWallets(thisMonth)
    // }

    if (this.walletState.dateOverview == null) {
      this.setDate(month)
      this.selectedDate = month
    } else {
      this.selectedDate = this.walletState.dateOverview
    }
    if (this.walletState.wallets == null && id) {
      this.getRecords({id, account_type: 'credit', month})
    }
  }

  protected openWallet() {
    this.$popup.open(OverviewWallet, {
      props: {
        angle: 90,
        color1: 'overviewWalletTop',
        color2: 'overviewWalletBottom',
        customCSS: 'overviewWallet',
        name: this.$t('profile.wallet.title'),
        monthProp: null,
        type: 'credit',
      },
      title: this.$t('profile.wallet.title'),
    })
  }

  protected openWalletDebit() {
    this.$popup.open(OverviewWallet, {
      props: {
        angle: 200,
        color1: 'overviewPrepaidTop',
        color2: 'overviewPrepaidBottom',
        customCSS: 'overviewPrepaid',
        name: this.$t('profile.wallet.prepaid.title'),
        monthProp: null,
        type: 'debit',
      },
      title: this.$t('profile.wallet.prepaid.title'),
    })
  }

  protected get prepaidEnabled() {
    // TODO: point to correct config key
    const l = this.configuration.appConfig.prepaid_wallet_enabled
    if (l === undefined) {
      return false
    }
    return l
  }
}
