
















import {Vue, Component} from 'vue-property-decorator'
import {Getter} from 'vuex-class'
import Utils from '@/utils'

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Container: Utils.loadComponent('proxy/Container'),
    Button: Utils.loadComponent('Button'),
    LightActivitiesCalendar: Utils.loadView('LightActivitiesCalendar'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
})

export default class LightActivities extends Vue {
  @Getter('isLogged') public isLogged!: boolean
}
