































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  Globalnote,
  InterventionRequestExtra,
  InterventionRequestStatus,
  ReservationFlag,
  ReservationResponse,
} from '@/lib/kepler/interfaces'
import {Action, State} from 'vuex-class'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'
import {BookingState} from '@/store/modules/booking'
import VehicleDetail from '@/components/entities/vehicle/VehicleDetail.vue'
import VehicleTypeChip from '@/components/entities/vehicle/VehicleTypeChip.vue'
import VehicleQuickSummary from '@/components/entities/vehicle/VehicleQuickSummary.vue'
import FuelLevel from '@/components/FuelLevel.vue'
import Swipe from '@/components/Swipe.vue'
import ServiceMesh from '@/lib/serviceMesh'
import List from '@/components/proxy/List/List.vue'
import ListTile from '@/components/proxy/List/ListTile.vue'
import ListTileTitle from '@/components/proxy/List/ListTileTitle.vue'
import sdk from '@/lib/kepler/sdk'

@Component({
  components: {
    ListTileTitle,
    ListTile,
    List,
    Swipe,
    FuelLevel,
    VehicleQuickSummary,
    VehicleTypeChip,
    VehicleDetail,
    ExtraActions: Utils.loadComponent('ExtraActions'),
    VehicleActions: Utils.loadComponent('VehicleActions'),
    BookingActions: Utils.loadComponent('BookingActions'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Container: Utils.loadComponent('proxy/Container'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    ReservationVehicleAndDates: Utils.loadComponent('entities/reservation/ReservationVehicleAndDates'),
    ReservationHighlights: Utils.loadComponent('entities/reservation/Highlights'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'BookingMaintenanceSummary',
})
export default class BookingMaintenanceSummary extends Vue {
  @State('booking') public booking!: BookingState

  @Action('setActionButtons') public setActionButtons: any
  @Action('globalNotes') public globalNotes!: () => Promise<Globalnote[]>
  @Action('bookingFlushFlags') public bookingFlushFlags: any
  @Action('current') public current!: () => void

  @Prop({type: Object, required: true}) public reservation!: ReservationResponse

  public topUp: boolean | null = null
  public loader: boolean | number = false
  public notes: Globalnote[] = []

  public get bookingFlags() {
    let p: boolean = false
    this.booking.bookingFlags.forEach((flag: ReservationFlag) => {
      if (flag.reservation_number === this.reservation.number) {
        const now = DateHelper.parse(DateHelper.now('YYYY-MM-DD HH:mm'))
        const value = DateHelper.parse(flag.value)
        p = value.diff(now, 'minutes') > 0
      }
    })
    return p
  }

  public get vehicleSlot() {
    return this.reservation?.vehicle_slot
  }

  public get interventionRequests(): InterventionRequestExtra[] | null {
    return this.reservation.extra.intervention_requests ?? null
  }

  public get highlights() {
    const r = this.reservation
    const vs = r.vehicle_slot
    const result = {
      start: '',
      end: '',
      bm: '',
      status: '',
      fuel: '',
      lot: '',
    }
    if (r) {
      result.bm = vs.reservation_type
      result.start = DateHelper.formatDate(r.start, this.$datetimeformat())
      if (!result.bm.endsWith('FF')) {
        result.end = DateHelper.formatDate(r.end, this.$datetimeformat())
      }
      result.status = this.$t(`status.booking.${r.status.toLowerCase()}`)

      const f = this.$isAvailable(`vehicle.fuel_types.${vs.vehicle.fuel_type}`) || vs.vehicle.fuel_type

      const fl = vs.vehicle.fuel_level ?? -1
      const rg = vs.vehicle.range
      result.fuel = `${fl >= 0 ? `${fl}%` : ''} ${f} ${rg ? `${rg}km` : ''}`
      result.lot = vs.lot.name
    }
    return result
  }

  public get mainColor() {
    return ServiceMesh.bmvtColor(this.reservation.vehicle_slot)
  }

  public statusColor(s: InterventionRequestStatus | 'REJECTED') {
    switch (s) {
      case 'COMPLETED':
        return 'success'
      case 'IN_PROGRESS':
        return 'warning'
      case 'REJECTED':
        return 'error'
      default:
        return ''
    }
  }

  protected get fuelIcon(): string {
    const electric = this.reservation.vehicle_slot.vehicle.fuel_type === 'ELECTRIC'
    const lvl = this.reservation.vehicle_slot.vehicle.fuel_level
    let str = 'mdi-'
    if (electric) {
      if (lvl) {
        str += 'battery-' + Math.round(lvl / 10) * 10
      } else {
        str += 'battery'
      }
    } else {
      if (lvl) {
        str += ['gauge-empty', 'gauge-low', 'gauge', 'gauge-full'][Math.round(lvl / 25)] // so fancy
      } else {
        str += 'gauge'
      }
    }
    return str
  }

  public interventionRequestUpdate(req: InterventionRequestExtra, status: 'IN_PROGRESS'|'COMPLETED'|'REJECTED') {
    sdk.maintenance.update({id: req.maintenance_request_id, status})
    this.current()
  }

  public created() {
    if (!this.reservation) {
      this.$router.push('/')
    } else {
      this.globalNotes().then((r) => {
        this.notes = r
      })
      if (!this.bookingFlags) {
        this.bookingFlushFlags()
      }
    }
  }
}
