













import {Vue, Component, Prop} from 'vue-property-decorator'

import {ValidationProvider} from 'vee-validate'
import DateField from '@/components/fields/DateField.vue'

@Component({
  components: {DateField, ValidationProvider},
  name: 'DateFieldWithValidation',
})
export default class DateFieldWithValidation extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null
}
