import 'typeface-open-sans'
import '@mdi/font/css/materialdesignicons.css'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import './lib/kepler/sdk'
import sdk from './lib/kepler/sdk'
import storage from '@/lib/storage'

import Vue from 'vue'
import {CombinedVueInstance} from 'vue/types/vue'

import AppContainer from './AppContainer.vue'

import {ObserveVisibility} from 'vue-observe-visibility'
// import './registerServiceWorker'
import cordovaLoader from './cordovaLoader'

import {ValidationProvider} from 'vee-validate'
import store from './store/index'
import router from './router'
import Vuetify from 'vuetify'
import './lib/vuetify/vuetify.styl'
import en from 'vuetify/src/locale/en'

import VueEnv, {envInstance} from './lib/plugins/env'
import VueStyle from './lib/plugins/style'
import VueLang from './lib/plugins/lang'
import VueLog from './lib/plugins/logger'
import Theme, {branding} from './lib/plugins/theme'
import Popups from './lib/plugins/popups'

import NMaps from './lib/n-maps/src/VueNMaps'
import * as mapConfig from './lib/map/map_config.json'
import {AppConfig} from '@/lib/kepler/interfaces'
import {isAppConfig} from '@/store/modules/configuration'
import {GetterTree} from 'vuex'

const c = storage.load('eliot_config')
const config: AppConfig | undefined = isAppConfig(c) ? c : undefined

Vue.directive('observe-visibility', ObserveVisibility)

Vue.component('ValidationProvider', ValidationProvider)

Vue.use(VueEnv)
Vue.use(VueStyle)
Vue.use(VueLang)
Vue.use(VueLog)
Vue.use(Theme)
Vue.use(Popups)

Vue.use(Vuetify, {
  theme: branding.theme,
  iconfont: 'mdi',
  lang: {
    locales: {en}, // TODO: load from available languages
    current: 'en',
  },
})

Vue.config.productionTip = (process.env.NODE_ENV === 'development')
export const EventBus = new Vue()

Vue.directive('visible', (el, bind): void => {
  el.style.visibility = (!!bind.value) ? 'visible' : 'hidden'
})

function cron(fun: () => void): void {
  fun()
  setTimeout(() => {
    cron(fun)
  }, 1000 * 60)
}

cron(() => {
  EventBus.$emit('cron1', 1)
})

EventBus.$on('cron1', () => {
  store.dispatch('timestamps')
  // we are nuking this every time there's an $on/$off like in Map.vue:295
  // TODO: find a more reliable method than this cron
})

router.beforeEach((to, from, next) => {
  const topbar = to.meta?.topbar as {title?: string} | undefined
  if (to.path !== from.path) { // don't count hash changes
    store.dispatch('setTopbarTitle', topbar?.title || null)
  }
  if (from.path === to.path) {
    next()
    return
  }
  if ((store.getters as GetterTree<any, any>).popupIsOpen && to.path !== from.path) {
    store.dispatch('closePopup')
    next(false)
    return false
  }
  if (store.state.topbar.topbarActions?.length) {
    store.dispatch('flushActionButtons').then(next)
  } else {
    next()
  }
})

export let vueInstance: CombinedVueInstance<Vue, any, any, any, any>

const loader = () => {
  Vue.use(NMaps, config?.map_configuration ?? mapConfig)

  store.dispatch('initRoot', envInstance.APP_VERSION_NUMBER).then((r) => {
    if (r) {
      router.replace({name: 'home'})
    }
  })

  cordovaLoader(() => {
    vueInstance = new Vue({
      router,
      store,
      render: (h) => h(AppContainer),
    }).$mount('#app')
    document.addEventListener('resume', () => {
      store.dispatch('flushConnectionErrors')
      store.dispatch('timestamps')
    }, false)
  })
}

if (storage.check('eliot_config')) {
  sdk.app.fastConfig()
    .then(loader).catch(() => {
      loader()
      sdk.app.config().then(() => {
        store.dispatch('loadConfig', true)
      })
    })
} else {
  sdk.app.config().then(loader)
}
