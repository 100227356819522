






import {Vue, Component} from 'vue-property-decorator'
import FullScreenPopup from '@/views/FullScreenPopup.vue'
import {Getter} from 'vuex-class'
import {Popup} from '@/lib/kepler/interfaces'
import App from '@/App.vue'

@Component({
  components: {FullScreenPopup, App},
})
export default class AppContainer extends Vue {
  @Getter('fullscreenPopup') public fullscreenPopup?: Popup
}
