





































import {Vue, Component, Prop} from 'vue-property-decorator'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import Badge from '@/components/proxy/Badge.vue'

type corner = 'top' | 'bottom' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left'

@Component({
  components: {Badge},
})
export default class NEOMWideButton extends Vue {
  @Prop({
    type: String,
    default: 'none',
  }) protected slant!: 'none' | 'wide' | 'square' | corner
  @Prop({
    type: String,
    default: 'transparent',
  }) protected color!: string
  @Prop({
    type: String,
    default: 'bottom-right',
  }) protected colorPosition!: corner
  @Prop({
    type: String,
    required: false,
  }) protected text?: string
  @Prop({
    type: Number,
    required: false,
    default: 420,
  }) protected width?: number
  @Prop({
    type: Number,
    required: false,
    default: 120,
  }) protected height?: number

  protected svgRef: SVGElement | null = null
  protected svgWidth: number = 0
  protected svgHeight: number = 0

  protected get parsedColor() {
    return VuetifyColorHelper.color(this.color)
  }

  protected get path() {
    // tslint:disable: cyclomatic-complexity // it's a switch-case ffs
    switch (this.slant) {
      case 'none':
        return 'M16.29 0h127.42s16.29 0 16.29 16.29v116.75s0 16.29 -16.29 16.29h-127.42s-16.29 0 -16.29 -16.29v-116.75s0 -16.29 16.29 -16.29'
      case 'wide':
        return 'M0 38.4C0 24.9587 0 18.2381 2.61584 13.1042C4.9168 8.58834 8.58834 4.9168 13.1042 2.61584C18.2381 0 24.9587 0 38.4 0H304.6C318.041 0 324.762 0 329.896 2.61584C334.412 4.9168 338.083 8.58834 340.384 13.1042C343 18.2381 343 24.9587 343 38.4V81.6C343 95.0413 343 101.762 340.384 106.896C338.083 111.412 334.412 115.083 329.896 117.384C324.762 120 318.041 120 304.6 120H38.4C24.9587 120 18.2381 120 13.1042 117.384C8.58834 115.083 4.9168 111.412 2.61584 106.896C0 101.762 0 95.0413 0 81.6V38.4Z'
      case 'square':
        return 'M15 0h140s15 0 15 15v140s0 15 -15 15h-140s-15 0 -15 -15v-140s0 -15 15 -15'
    }
  }

  protected get cPos() {
    let x = this.svgWidth ?? 177
    const y = this.svgHeight ?? 150
    switch (this.colorPosition) {
      case 'top':
        return {x: x / 2, y: 0}
      case 'bottom':
        return {x: x / 2, y}

      case 'top-left':
        x = ['top', 'top-left'].includes(this.slant) ? 48 : 0
        return {x, y: 0}
      case 'top-right':
        x = ['top', 'top-right'].includes(this.slant) ? x - 48 : x
        return {x, y: 0}
      case 'bottom-left':
        x = ['bottom', 'bottom-left'].includes(this.slant) ? 48 : 0
        return {x, y}
      case 'bottom-right':
      default: {
        x = ['bottom', 'bottom-right'].includes(this.slant) ? x - 48 : x - 43
        return {x, y}
      }
    }
  }

  protected mounted() {
    this.svgRef = this.$refs.svg as SVGElement
    this.svgWidth = this.svgRef?.clientWidth
    this.svgHeight = this.svgRef?.clientHeight
  }
}
