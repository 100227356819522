interface CameraPresetInterface {
  height: number
  width: number
  direction?: 'FRONT' | 'BACK'
  canCrop?: boolean
  canUseCamera: boolean
  canUseDocument?: boolean
  canUseStorage?: boolean
  canUseUpload?: boolean
}

class CameraDefaultOptions {
  allowEdit = false
  cameraDirection = 0
  correctOrientation = true
  destinationType = 0
  encodingType = 0
  mediaType = 0
  popoverOptions = undefined
  quality = 80
  saveToPhotoAlbum = false
  sourceType = 1
  targetHeight = 512
  targetWidth = 512
}

// TODO: use motherFuctory pattern
export default class CameraPreset {
  public constructor(p: string) {
    const preset = this.getPreset(p)
    this.ratio = preset.height / preset.width
    this.canUseCamera = preset.canUseCamera
    this.canUseDocument = preset.canUseDocument || false
    this.canUseStorage = preset.canUseStorage || false
    this.canCrop = preset.canCrop || false
    this.canUseUpload = preset.canUseUpload || false

    const options = new CameraDefaultOptions()
    options.targetWidth = preset.width
    options.targetHeight = preset.height
    options.cameraDirection = Camera.Direction[preset.direction || 'BACK']
    options.sourceType = !this.canUseCamera && this.canUseStorage ? Camera.PictureSourceType.PHOTOLIBRARY : Camera.PictureSourceType.CAMERA
    // ...
    this.cameraOptions = options
  }
  public getPresets(): { [key: string]: CameraPresetInterface } {
    return {
      base: {
        width: 512,
        height: 512,
        direction: 'FRONT',
        canUseCamera: true,
        canCrop: true,
        canUseUpload: true,
      },
      document: {
        width: 2048,
        height: 1228,
        direction: 'BACK',
        canCrop: true,
        canUseDocument: true,
        canUseCamera: true,
        canUseStorage: false,
        canUseUpload: true,
      },
      selfie: {
        width: 1024,
        height: 1024,
        canCrop: true,
        canUseCamera: true,
        canUseStorage: false,
        canUseUpload: true,
      },
      report: {
        width: 4096,
        height: 4096,
        canCrop: false,
        canUseCamera: true,
        canUseStorage: false,
        canUseUpload: true,
      },
    }
  }
  private getPreset(p: string) {
    const presets = this.getPresets()
    return presets[p] || presets.base
  }

  public cameraOptions: CameraOptions
  public ratio: number
  public canUseCamera: boolean
  public canUseDocument: boolean
  public canUseStorage: boolean
  public canCrop: boolean
  public canUseUpload: boolean
}
