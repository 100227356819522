






























































































































import {Component, Vue} from 'vue-property-decorator'
import Utils from '@/utils'
import {
  VAlert,
  VBtn,
  VCard,
  VChip,
  VExpansionPanel,
  VExpansionPanelContent,
  VFlex,
  VIcon,
  VLayout,
  VProgressCircular,
  VSelect,
  VSpacer,
} from 'vuetify/lib'
import sdk from '@/lib/kepler/sdk'
import Locate, {ExtendedPos} from '@/lib/location'
import {
  InterventionParkingLot,
  InterventionRequest,
  InterventionRequestReason,
  InterventionRequestStatus,
  Position,
  ReservationResponse,
  UserMesh,
  VehicleType,
} from '@/lib/kepler/interfaces'
import {Action, Getter, State} from 'vuex-class'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import Layout from '@/components/proxy/Layout.vue'
import moment from 'moment/moment'
import Divider from '@/components/proxy/Divider.vue'
import {MaintenanceState} from '@/store/modules/maintenance'

type InterventionRequestPageData = Partial<Record<InterventionRequestStatus, InterventionRequest[]>>

@Component({
  components: {
    Divider,
    Layout,
    VLayout,
    VFlex,
    VAlert,
    VSelect,
    VCard,
    VExpansionPanel,
    VExpansionPanelContent,
    VChip,
    VSpacer,
    VProgressCircular,
    VBtn,
    VIcon,
    Button: Utils.loadComponent('Button'),
    Plate: Utils.loadComponent('Plate'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
  },
})
export default class Maintenance extends Vue {
  @State('maintenance') public ms!: MaintenanceState
  @Getter('defaultLocation') public defaultLocation!: { acc: null; lng: number; lat: number } | null
  @Getter('maintenance') public isMaintenance!: boolean
  @Getter('activeReservations') public activeReservations!: ReservationResponse[]
  @Getter('maintenanceReq') public maintenanceReq!: any

  @Action('current') public current!: () => Promise<ReservationResponse[]>
  @Action('getUserMesh') public getUserMesh!: () => Promise<UserMesh[]>
  @Action('getMaintenanceZones') public getZones!: () => Promise<void>
  @Action('getMaintenanceLots') public getLots!: () => Promise<void>
  @Action('setMaintenanceLot') public setLot!: (p: InterventionParkingLot) => Promise<void>
  @Action('setMaintenanceZone') public setZone!: (z: string) => Promise<void>
  @Action('setVehicleType') public setType!: (vt: keyof typeof VehicleType | null) => Promise<void>
  @Action('setUserPosition') public setPosition!: (p: Position) => Promise<void>
  @Action('getInterventionRequests') public getRequests!: () => Promise<void>
  @State((state) => state.profile.userMesh) public userMesh!: UserMesh[]

  public position: Position = {latitude: 0, longitude: 0}
  public accuracy: number = 20

  public interventionZones: string[] = []

  public vehicleTypes: Array<keyof typeof VehicleType> = []
  public selectedVehicleType: keyof typeof VehicleType | null = null

  public loading: boolean = false
  public takeLoading: false | string = false
  public loadingText: string = ''
  public error: string = ''
  public warning: string = ''
  public noPos = false

  public icons: Record<InterventionRequestReason, { name: string, color: string }> = {
    ALMOST_EMPTY: {name: 'mdi-gas-station', color: 'warning'},
    BACKOFFICE_DISABLE: {name: 'mdi-car-off', color: ''},
    BATTERY: {name: 'mdi-car-battery', color: ''},
    BATTERY_EMPTY: {name: 'mdi-car-battery', color: 'error'},
    CLEANING: {name: 'mdi-spray-bottle', color: ''},
    EV_PLUGGED_IN: {name: 'mdi-power-plug', color: 'success'},
    FUEL_UNDER_LIMIT_THRESHOLD: {name: 'mdi-gas-station', color: 'error'},
    NO_RES_IN_DAYS: {name: 'mdi-clock-alert-outline', color: ''},
    ORDINARY_MAINTENANCE: {name: 'mdi-wrench-clock', color: ''},
    RANGE_UNDER_LIMIT_THRESHOLD: {name: 'mdi-gauge-empty', color: 'error'},
    REPOSITIONING: {name: 'mdi-parking', color: ''},
  }

  protected get currentResNums() {
    return this.activeReservations.map((r) => r.number)
  }

  protected isTaken(i: InterventionRequest) {
    const num = i.reservation_number
    return i.status === 'TAKEN' || (num && !this.currentResNums.includes(num))
  }

  protected accuratePosition(maxWait: number = 1): Promise<Position> {
    return new Promise<Position>(((resolve, reject) => {
        let positionWatch: number
        let timeoutWatch: NodeJS.Timeout

        let skippedFirst = false

        const clean = () => {
          clearTimeout(timeoutWatch)
          navigator.geolocation.clearWatch(positionWatch)
        }

        const onError = (msg: string) => {
          clean()
          reject(msg)
        }

        const onSuccess = (position: Position) => {
          clean()
          resolve(position)
        }

        positionWatch = navigator.geolocation.watchPosition((position: GeolocationPosition) => {
          if (skippedFirst) {
            if (position.coords.accuracy <= this.accuracy) {
              onSuccess({latitude: position.coords.latitude, longitude: position.coords.longitude})
            }
          } else {
            skippedFirst = true
          }
        }, (error: GeolocationPositionError) => {
          onError('GPS_ERROR_' + JSON.stringify(error))
        }, {
          maximumAge: 0,
          timeout: maxWait * 1000,
          enableHighAccuracy: true,
        })

        timeoutWatch = setTimeout(() => {
          onError('TIMEOUT')
        }, maxWait * 1000)
      }
    ))
  }

  protected getPosition(): Promise<Position> {
    return new Promise((resolve) => {
      this.loadingText = 'getting location...'
      this.accuratePosition(3)
        .then((position) => {
          this.position = position
          resolve(position)
        })
        .catch(() => {
          Locate.locate((pos: ExtendedPos) => {
              this.loadingText = 'accurate location unavailable, still working...'
              this.$log('got position: ' + JSON.stringify(pos), 1)
              const position: Position = {latitude: pos.lat, longitude: pos.lng}

              this.position = position
              resolve(position)
            },
            this.defaultLocation ? this.defaultLocation : {lat: 0, lng: 0, acc: null},
            () => {
              this.noPos = true
              const d = this.defaultLocation
              this.warning = this.$t('maintenance.no_location_available')
              this.position = d ? {latitude: d.lat, longitude: d.lng} : {latitude: 0, longitude: 0}

              resolve(this.position)
            },
          )
        })
    })
  }

  protected search() {
    this.loading = true
    this.loadingText = 'updating data...'

    this.getRequests().finally(() => {
      this.loading = false
      this.loadingText = ''
    })
  }

  protected getVehicleTypes() {
    this.loadingText = 'getting vehicle types...'
    return this.getUserMesh().then((m) => {
      m.forEach((mesh) => {
        if (mesh.vehicle_type && !this.vehicleTypes.includes(mesh.vehicle_type)) {
          this.vehicleTypes.push(mesh.vehicle_type)
        }
      })
      if (this.vehicleTypes.length === 1) {
        this.selectedVehicleType = this.vehicleTypes[0]
      }
    })
  }

  protected onLotSelection(p: InterventionRequest) {
    this.$log(p, 1)
    this.takeLoading = p.maintenance_request_id
    const stopLoading = () => this.takeLoading = false
    if (p.reservation_number && this.currentResNums.includes(p.reservation_number)) {
      this.current().then((curr) => {
        const id = curr.find((r) => r.number === p.reservation_number)?.id
        if (id) {
          this.$router.push({name: 'reservation', params: {id}})
        }
      }).finally(stopLoading)
    } else if (p.maintenance_request_id) {
      sdk.maintenance.takeMaintenance(p.maintenance_request_id).then(({data}) => {
        this.current().then(() => {
          this.$router.push({name: 'reservation', params: {id: data.id}})
        }).finally(stopLoading)
      }).catch(stopLoading)
    } else {
      sdk.maintenance.take(p.plate).then((r) => {
        this.current().then(() => {
          stopLoading()
          this.$dialog.open(ConfirmDialogCallback, {
            props: {
              imageState: '',
              confirmText: this.$t('maintenance.confirmed.go_to_reservation'),
              cancelText: this.$t('maintenance.confirmed.stay_on_page'),
              code: null,
              title: this.$t('maintenance.confirmed.title'),
              subtitle: null,
              showCloseButton: true,
              confirmCallback: () => {
                const reservation = r.data
                this.$router.push({name: 'reservation', params: {id: reservation.id}})
              },
              cancelCallback: () => {
                //
              },
            },
          })
        })
      }).finally(stopLoading)
    }
  }

  protected request() {
    const req = () => new Promise<void>((resolve, reject) => {
      this.getPosition().then((p) => {
        this.setPosition(p)
        this.loadingText = 'getting data...'
        this.getLots().then(resolve)
      }).catch(() => {
        this.$log('cannot get position', 3)
        this.error = 'cannot get position, check if location is enabled'
        this.loading = false
        reject()
      })
    })

    this.loading = true
    return req().finally(() => {
      this.loadingText = ''
      this.loading = false
    })
  }

  protected distanceString(d: number) {
    const str = ''
    if (this.$distance().toLowerCase() === 'km') {
      return str + ' ' + (d >= 1000 ? this.$distance(Math.round((d / 1000) * 10) / 10) : Math.floor(d) + 'm')
    } else {
      return str + ' ' + this.$distance(Math.round(d))
    }
  }

  protected timeFormat(t: string) {
    const m = moment(t, 'YYYY-MM-DD HH:mm:ss')
    if (m.isSame(moment(), 'day')) {
      return m.format(this.$timeformat())
    } else {
      return m.format(this.$datetimeformat())
    }
  }

  protected init() {
    this.error = ''
    this.warning = ''
    this.request()
    if (this.ms.lots.length < 2 && this.ms.zones.length < 2) {
      this.search()
    }
  }

  protected mounted() {
    this.getVehicleTypes().finally(this.init)
  }

  protected created() {
    if (!this.isMaintenance) {
      this.$router.replace({name: 'home'})
    }
  }
}
