


















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import Icon from './proxy/Icon.vue'
import moment, {Moment} from 'moment'
import Layout from './proxy/Layout.vue'
import InputDatePicker from './proxy/Inputs/InputDatePicker.vue'

// TODO: direct selection from date

@Component({
  components: {
    InputDatePicker,
    Layout,
    Icon,
  },
})
export default class MonthSwitcher extends Vue {
  @Prop({}) public date?: string
  @Prop({
    default: false,
    type: Boolean,
  }) public pastOnly?: boolean

  public dateString: string = ''

  public get max() {
    return moment().format('YYYY-MM')
  }
  public get min() {
    return moment('2000-01-01').format('YYYY-MM')
  }

  protected created() {
    const date = moment(this.date).isValid() ?  moment(this.date) : moment()
    this.dateString = date.format('YYYY-MM')
  }

  // Change month (add, subtract or set)
  private up() {
    this.dateString = this.formatDateToSend(moment(this.dateString).add(1, 'M'))
  }

  private down() {
    this.dateString = this.formatDateToSend(moment(this.dateString).subtract(1, 'M'))
  }

  private setDate(date: string) {
    this.dateString = date
  }

  // Watch and emit if changed
  @Watch('dateString')
  private onChangeEmit() {
    const normalized = this.formatDateToSend(moment(this.dateString))
    this.$emit('monthChanged', normalized)
  }

  // Check if the current date is in the future
  private isFuture() {
    const now = moment().format('YYYY-MM')
    return this.pastOnly && this.dateString === now
  }

  // Format callback for the date picker
  private formatDate: (date: string) => string = (date) => {
    return moment(date).format('MMMM YYYY')
  }

  private formatDateToSend(m: Moment) {
    return m.locale('en').format('YYYY-MM')
  }
}
