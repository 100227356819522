

















import {Component, Prop, Vue} from 'vue-property-decorator'
import {VChip} from 'vuetify/lib'
import Badge from '@/components/proxy/Badge.vue'
import Chip from '@/components/proxy/Chip.vue'
import Layout from '@/components/proxy/Layout.vue'
import Btn from '@/components/proxy/Btn.vue'

@Component({
  components: {Btn, Layout, Chip, Badge, VChip},
})
export default class Swipe extends Vue {
  @Prop({type: String, default: ''}) protected text!: string
  @Prop({type: String, default: 'grey'}) protected color!: string
  @Prop({type: Boolean, default: false}) protected reset!: boolean
  @Prop({type: Boolean, default: false}) protected reverse!: boolean
  @Prop({type: String, default: 'success'}) protected colorEnd!: string

  protected unlocked: boolean = false
  protected selected: boolean = false
  protected outerRect: null | DOMRect = null
  protected sliderRect: null | DOMRect = null
  protected outerRef: Element | null = null
  protected sliderRef: HTMLElement | null = null
  protected dragProps: null | { start: number, mouseStart: number, newX: number } = null
  protected observer: IntersectionObserver | null = null

  protected get rtl() {
    const g = this.$vuetify.rtl ? 1 : -1
    const l = this.reverse ? -1 : 1
    return g * l > 0
  }

  protected get maxX() {
    if (this.outerRect && this.sliderRect) {
      return Math.ceil(this.outerRect.width - this.sliderRect.width - 12) * (this.rtl ? -1 : 1)
    }
    return null
  }

  protected get icon() {
    if (this.unlocked) {
      return 'mdi-check'
    } else {
      if (this.rtl) {
        return 'mdi-chevron-double-left'
      } else {
        return 'mdi-chevron-double-right'
      }
    }
  }

  protected dragStart(e: Event) {
    if (this.unlocked) {
      return
    }
    this.sliderRect = this.sliderRef?.getBoundingClientRect() ?? null
    if (this.sliderRect && this.outerRect) {
      const x = this.getX(e)
      this.dragProps = {
        start: this.sliderRect.left - this.outerRect.left,
        mouseStart: x,
        newX: 0,
      }
      this.selected = true
      document.addEventListener('mousemove', this.dragLock, false)
      document.addEventListener('touchmove', this.dragLock, false)
      document.addEventListener('mouseup', this.dragStop)
      document.addEventListener('touchend', this.dragStop)
    }
  }

  protected dragStop(e?: Event, reset?: boolean) {
    if (this.sliderRef) {
      this.selected = false
      if (reset !== false) {
        this.sliderRef.style.left = '0px'
      }
      document.removeEventListener('mousemove', this.dragLock, false)
      document.removeEventListener('touchmove', this.dragLock, false)
      document.removeEventListener('mouseup', this.dragStop)
      document.removeEventListener('touchend', this.dragStop)
    }
  }

  protected dragLock(e: Event) {
    e.preventDefault()
    if (this.maxX && this.sliderRef && this.dragProps) {
      const rtl = this.maxX < 0
      const mouseDiff = this.getX(e) - this.dragProps.mouseStart
      const start = rtl ? 0 : this.dragProps.start
      let newX = start + mouseDiff
      newX = this.clamp(newX, [0, this.maxX])
      this.sliderRef.style.left = newX + 'px'
      if (newX === this.maxX) {
        this.dragStop(undefined, false)
        this.$emit('unlock')
        this.unlocked = true
      }
    }
  }

  protected clamp(value: number, range: [number, number]) {
    const [a, b] = range
    const min = Math.min(a, b)
    const max = Math.max(a, b)
    return Math.min(Math.max(value, min), max)
  }

  protected getX(event: Event) {
    let result = 0
    if (this.isTouchEvent(event)) {
      result = event.touches[0].pageX
    } else if (this.isDragEvent(event)) {
      result = event.clientX
    }
    return result
  }

  protected isDragEvent(e: Event): e is DragEvent {
    return 'clientX' in e
  }

  protected isTouchEvent(e: Event): e is TouchEvent {
    return 'touches' in e
  }

  protected onResize(outer?: boolean) {
    if (outer && this.outerRef) {
      if (!this.observer && !this.outerRef?.clientWidth) {
        this.observer = new IntersectionObserver(() => {
          this.onResize(true)
          this.onResize()
          if (this.observer && this.outerRef && this.outerRef?.clientWidth) {
            this.observer.unobserve(this.outerRef)
          }
        }, {root: this.outerRef.parentElement})
        this.observer.observe(this.outerRef)
      }
      this.outerRect = this.outerRef.getBoundingClientRect() ?? null
    } else if (this.sliderRef) {
      this.sliderRect = this.sliderRef.getBoundingClientRect() ?? null
    }
  }

  protected resetStatus() {
    this.unlocked = false
    if (this.sliderRef) {
      this.sliderRef.style.left = '0'
    }
  }

  protected mounted() {
    const outerRef = this.$refs.outer
    const sliderRef = this.$refs.slider

    if (outerRef && '$el' in outerRef) {
      this.outerRef = outerRef.$el
      this.onResize(true)
    }
    if (sliderRef && '$el' in sliderRef) {
      this.sliderRef = sliderRef.$el as HTMLElement
      this.onResize()
    }
  }

  protected beforeDestroy() {
    if (this.observer && this.outerRef) {
      this.observer.unobserve(this.outerRef)
    }
  }
}
