























































import {Component, Prop, Watch} from 'vue-property-decorator'

import {ValidationProvider} from 'vee-validate'
import {EventBus} from '@/main'
import Utils from '@/utils'
import {
  VRadio,
  VRadioGroup,
  VSlideXReverseTransition,
  VSlideXTransition,
  VSlideYReverseTransition,
} from 'vuetify/lib'
import PaymentMethods from '@/components/wallet/PaymentMethods.vue'

@Component({
  components: {
    Btn: Utils.loadComponent('proxy/Btn'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Button: Utils.loadComponent('Button'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    EmptyList: Utils.loadComponent('EmptyList'),
    ColorCard: Utils.loadComponent('ColorCard'),
    Container: Utils.loadComponent('proxy/Container'),
    CardButton: Utils.loadComponent('CardButton'),
    PaymentMethods: Utils.loadComponent('wallet/PaymentMethods'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    ValidationProvider,
    VRadio,
    VRadioGroup,
    VSlideXTransition,
    VSlideXReverseTransition,
    VSlideYReverseTransition,
  },
  name: 'PaymentMethodsWithValidation',
})
export default class PaymentMethodsWithValidation extends PaymentMethods {
  @Prop({
    type: [String],
    default: '',
  }) public readonly rules!: string

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  protected innerValue: string | null = null

  protected editEnabled: boolean = false

  protected get selectOnly(): boolean {
    return this.rules.includes('select_only') || false
  }

  protected get alwaysEdit() {
    return this.rules.includes('always_edit') || false
  }

  @Watch('innerValue')
  protected onInnerValueChange(newVal: any) {
    this.$emit('input', newVal)
  }

  @Watch('value')
  protected onValueChange(newVal: any) {
    this.innerValue = newVal
  }

  protected created() {
    if (this.alwaysEdit) {
      this.editEnabled = true
    }
    EventBus.$on('paymentMethodAdded', this.refresh)
    this.refresh()
  }

  protected refresh() {
    this.loading = 'all'
    this.init().then(() => {
      this.innerValue = this.value ? this.value : this.defaultPaymentMethodId
      if (this.paymentMethods?.length === 1) {
        this.innerValue = this.paymentMethods[0].id
      }
    }).finally(() => {
      this.loading = ''
    })
  }

  protected toggleEdit() {
    this.editEnabled = !this.editEnabled
  }
}
