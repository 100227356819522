export default class {
  static save(key: string, payload: unknown) {
    localStorage.setItem(key, JSON.stringify(payload))
  }

  static load(key: string) {
    const data = localStorage.getItem(key)
    if (data) {
      let r: unknown
      if (data === 'undefined') {
        localStorage.setItem(key, '')
        return undefined // in case of fuckery
      }
      try {
        r = JSON.parse(data)
      } catch (e) {
        console.error('Bad Data!')
        return undefined
      }
      return r
    }
  }

  static delete(key: string) {
    localStorage.removeItem(key)
  }

  static restore(key: string, target: any) {
    const data = this.load(key)
    if (data) {
      Object.assign(target, data)
    }
  }

  static check(key: string) {
    return !!localStorage.getItem(key)
  }
}
