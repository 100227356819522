








































































import {Component, Prop} from 'vue-property-decorator'
import Login from '@/views/Login.vue'
import {LoginResponse} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import PhoneVerificationView from '@/views/PhoneVerification.vue'
import ResetPasswordView from '@/views/ResetPassword.vue'
import sdk from '@/lib/kepler/sdk'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Button: Utils.loadComponent('Button'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    CloseButton: Utils.loadComponent('CloseButton'),
    PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
  },
  name: 'LoginDialog',
})
export default class LoginDialog extends Login {
  @Prop({type: Function}) protected callback!: (p: LoginResponse) => void
  @Prop({type: Boolean, default: false}) protected leaveOpen!: boolean
  protected dialog = true

  protected loginRequest() {
    if ((this.$refs?.loginForm as any)?.validate()) {
      this.loading = true
      sdk.people.login(this.credentials).then((r) => {
        this.callback(r.data)
        if (!this.leaveOpen) {
          this.$dialog.close()
        }
      }).catch(() => {
        // this.valid = false
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected checkSMS(title?: string) {
    this.$dialog.open(PhoneVerificationView, {
      props: {
        mobileNumber: this.phone, isDialog: true, quick: false, title, callback: (r: LoginResponse) => {
          this.callback(r)
          this.loading = false
          if (!this.leaveOpen) {
            this.$dialog.close()
          }
        },
      },
    })
  }

  protected openResetPasswordView() {
    this.$dialog.close()
    this.$popup.open(ResetPasswordView, {title: this.$t('login.reset_your_password')})
  }

  protected mounted() {
    this.$smoothReflow?.({
      property: ['height'],
      el: 'div.login-dialog',
      hideOverflow: true,
      transitionEvent: {
        selector: '.layout',
      },
    })
  }
}
