



















import {Component, Prop, Vue} from 'vue-property-decorator'
import {VehicleSlot} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Img: Utils.loadComponent('proxy/Image'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Plate: Utils.loadComponent('Plate'),
    VehicleTypeChip: Utils.loadComponent('entities/vehicle/VehicleTypeChip'),
  },
  name: 'VehicleQuickSummary',
})
export default class VehicleQuickSummary extends Vue {
  @Prop() protected vehicleSlot!: VehicleSlot
}
