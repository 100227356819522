















import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import smoothReflow from 'vue-smooth-reflow'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Loader: Utils.loadComponent('Loader'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'LoaderDialog',
  mixins: [smoothReflow],
})
export default class LoaderDialog extends Vue {

  @Prop({type: String}) protected buttonText!: string
  @Prop({type: String, default: 'buttonDefaultColor'}) protected buttonColor!: string

  @Prop({type: [String, Array], default: ''}) protected text!: string | string[]
  @Prop({type: Boolean, default: false}) protected showCloseButton!: boolean
  @Prop({type: Boolean, default: true}) protected showLoader!: boolean

  @Prop({type: Function}) protected closeCallback?: () => void
  @Prop({type: Function}) protected buttonCallback!: () => void
  @Prop({type: Function}) protected endCallback?: () => void

  protected loading: boolean = false
  protected parsedText: string = ''

  protected randomPhraseCycler: NodeJS.Timeout | null = null

  protected getRandomPhrase(): void {
    if (Array.isArray(this.text)) {
      const interval = Math.floor(Math.random() * 1300) + 200
      const randomIndex = Math.floor(Math.random() * this.text.length)
      this.parsedText = this.text[randomIndex]
      this.randomPhraseCycler = setTimeout(this.getRandomPhrase, interval)
    }
  }

  protected mounted() {
    if (Array.isArray(this.text)) {
      this.parsedText = this.text[0]
      this.randomPhraseCycler = setTimeout(this.getRandomPhrase, 1000)
    } else {
      this.parsedText = this.text
    }

    this.$smoothReflow?.({
      property: ['height', 'transform'],
      el: '.loader-dialog',
      hideOverflow: true,
    })
  }

  protected beforeDestroy() {
    if (this.randomPhraseCycler) {
      clearTimeout(this.randomPhraseCycler)
    }
  }
}
