
import {Vue, Component} from 'vue-property-decorator'
import {VTextField} from 'vuetify/lib'

@Component({
  name: 'TextField',
  extends: VTextField as any,
})
export default class TextField extends Vue {
}
