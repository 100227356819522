
import {Vue, Component} from 'vue-property-decorator'
import {VTextarea} from 'vuetify/lib'

@Component({
  name: 'TextArea',
  extends: VTextarea as any,
})
export default class TextArea extends Vue {
}
