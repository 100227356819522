import {Vue} from 'vue-property-decorator'
import {EventBus} from '@/main'
import BrowserCameraClass from '@/lib/CameraBrowser'

export class CameraHelperSimple {

  public width: number | null = null
  public height: number | null = null

  public result: string | null = null
  public options!: CameraOptions

  private readonly platform: string = ''
  private readonly isBrowser: boolean = false
  private readonly browserCamera?: BrowserCameraClass

  constructor(opts: CameraOptions) {
    this.platform = device.platform.toLowerCase()
    this.isBrowser = this.platform === 'browser'
    Vue.set(this, 'options', opts)
    Vue.set(this, 'width', opts.targetWidth ? opts.targetWidth : null)
    Vue.set(this, 'height', opts.targetHeight ? opts.targetHeight : null)
    if (this.isBrowser) {
      this.browserCamera = new BrowserCameraClass(this.options)
    }
  }

  public getPicture() {
    return new Promise((resolve: (r: string) => void, reject) => {
      // dataURL flow
      Vue.set(this.options, 'destinationType', Camera.DestinationType.DATA_URL)
      Vue.set(this.options, 'encodingType', Camera.EncodingType.JPEG)
      this.snap().then((dataUrl) => {
        const blob = URL.createObjectURL(this.blobFromDataUrl(dataUrl))
        Vue.set(this, 'result', blob)
        EventBus.$emit('gotPicture', blob)
        resolve(blob)
      }).catch((e: string) => {
        console.error(e)
        EventBus.$emit('cameraError')
        reject(e)
      })
    })
  }

  public getData() {
    return new Promise((resolve: (r: string) => void, reject) => {
      // dataURL flow
      Vue.set(this.options, 'destinationType', Camera.DestinationType.DATA_URL)
      Vue.set(this.options, 'encodingType', Camera.EncodingType.JPEG)
      this.snap().then((dataUrl) => {
        resolve(dataUrl)
      }).catch((e: string) => {
        console.error(e)
        EventBus.$emit('cameraError')
        reject(e)
      })
    })
  }

  public cleanUp() {
    if (this.isBrowser && this.browserCamera) {
      this.browserCamera.cleanUp()
    } else {
      return new Promise<void>((resolve, reject) => {
        navigator.camera.cleanup(resolve, reject)
      })
    }
  }

  private snap() {
    return new Promise((resolve: (uri: string) => void, reject) => {
      if (this.isBrowser && this.browserCamera) {
        this.browserCamera.takePicture(resolve, reject)
      } else {
        navigator.camera.getPicture(resolve, reject, this.options)
      }
      if (this.isBrowser) {
        BrowserCameraClass.prototype.styleBrowserCamera()
      }
    })
  }

  // blob from base64
  private blobFromDataUrl(b64Data: string, contentType?: string, sliceSize = 512) {
    let enc = ''
    switch (this.options.encodingType) {
      case 0:
        enc = 'image/JPEG'
        break
      case 1:
        enc = 'image/PNG'
    }
    contentType = contentType || enc

    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, {type: contentType})
  }

}
