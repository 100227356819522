







import {Vue, Component, Prop} from 'vue-property-decorator'
import {DamagePoints} from '@/lib/kepler/interfaces'

@Component({})
export default class DamagePointsComponent extends Vue {
  @Prop() protected points!: DamagePoints[]
}
