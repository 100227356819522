import {ActionTree, GetterTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {AppConfig, CheckpointRequest, HomeModeEnum, LoginProvider, SplashButtons} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'
import Vue from 'vue'
import {$langToFlag, Language} from '@/lib/plugins/lang'
import storage from '@/lib/storage'

interface AppVersion {
  version: number
  version_required: number
}

interface AppRelease {
  release: string
  release_required: string
}

export class AppConfigState {
  public appConfig!: AppConfig
  public versionNumber: number = 0
  public versionNumberRequired: number = 0
  public release: string = ''
  public releaseRequired: string = ''
  public homeMode: HomeModeEnum = HomeModeEnum.map
  public language: Language | string = ''
}

export function isAppConfig(maybeConfig: unknown): maybeConfig is AppConfig {
  const props = ['default_latitude', 'default_longitude', 'default_plan', 'currency']
  const isObject = typeof maybeConfig === 'object' && maybeConfig !== null
  return isObject && props.every((key) => key in maybeConfig)
}

const mutations: MutationTree<AppConfigState> = {
  SET_CONFIG(cfState, payload: AppConfig) {
    cfState.appConfig = payload
  },
  SET_APP_VERSION(cfState, payload: AppVersion) {
    cfState.versionNumber = payload.version
    cfState.versionNumberRequired = payload.version_required
  },
  SET_APP_RELEASE(cfState, payload: AppRelease) {
    cfState.release = payload.release
    cfState.releaseRequired = payload.release_required
  },
  SET_HOME_MODE(cfState, payload?: HomeModeEnum) {
    if (payload) {
      cfState.homeMode = payload
    } else if (!cfState.homeMode) {
      cfState.homeMode = HomeModeEnum.map
    }
  },
  SET_SPLASH(cfState, payload: SplashButtons[]) {
    if (cfState.appConfig === undefined) {
      return
    }
    cfState.appConfig.splash_configuration = payload
  },
  SET_LANG(stateO, payload: string) {
    stateO.language = payload
  },
}

const actions: ActionTree<AppConfigState, RootState> = {
  init({dispatch}) {
    dispatch('loadConfig')
    dispatch('setHomeMode')
  },
  loadConfig({commit}) {
    const c = storage.load('eliot_config')
    const config: AppConfig | undefined = isAppConfig(c) ? c : undefined
    commit('SET_CONFIG', config)
    return config
  },
  checkpoint({commit}, payload: CheckpointRequest) {
    return sdk.profile.checkpoint(payload).then((r) => {
      commit('SET_APP_VERSION', {
        version: Number(Vue.prototype.$env.APP_VERSION_NUMBER),
        version_required: r.data.current_level || 0,
      })
      commit('SET_APP_RELEASE', {
        release: Vue.prototype.$env.RELEASE,
        release_required: r.data.current_release,
      })
      return r.data
    })
  },
  setHomeMode({commit}, homeMode?: keyof typeof HomeModeEnum) {
    if (homeMode) {
      commit('SET_HOME_MODE', homeMode)
    }
  },
  setSplash({commit}, splashConfig: SplashButtons[]) {
    commit('SET_SPLASH', splashConfig)
  },
  setLang({commit, dispatch}, payload: string) {
    commit('SET_LANG', payload)
    dispatch('sleep', 'set_lang')
  },
}

const getters: GetterTree<AppConfigState, RootState> = {
  defaultLocation: (state) => {
    const lat = state.appConfig?.default_latitude
    const lng = state.appConfig?.default_longitude
    if (lat && lng) {
      return {lat, lng, acc: null}
    }
    return null
  },
  defaultPlanId: (state) => state.appConfig?.default_plan,
  remindBookingModesThreshold: (state) => state.appConfig?.bm_reminder_threshold,
  fieldConfigs: (state) => state.appConfig?.fields_configuration,
  allowedLanguages: (state) => state.appConfig?.fields_configuration.allowedLanguages?.split(',') ?? [],
  appConfig: (state) => state.appConfig,
  splashButtons: (state) => state.appConfig?.splash_configuration,
  loginProviders: (state, {fieldConfigs}) => {
    const conf = fieldConfigs?.login_providers?.split('|')
    const providers: LoginProvider[] = []
    // TODO: pester backend for a less shitty-ass solution, like a proper list API.
    if (conf?.includes('grandid')) {
      providers.push({
        id: 'grandid',
        name: 'login.bankID',
        color: '#183E4F',
        icon: 'https://cdn.playmoove.com/public/bila/BankID_logo_neg.svg',
      })
    }

    return providers
  },
  currentLanguage: ({language}) => language,
  currentLanguageFlag: ({language}) => $langToFlag(language),
  documentTypes: (s) => s.appConfig?.types.documents,
  clientTypes: (s) => s.appConfig?.types.client,
}

export default {
  state: new AppConfigState(),
  mutations,
  actions,
  getters,
}
