
import {Component} from 'vue-property-decorator'
import BaseFlow from '@/views/Flows/BaseFlow.vue'
import {Action} from 'vuex-class'

@Component({})
export default class FlowQuickRegistration extends BaseFlow {
  @Action('editField') public editField!: (payload: { path: Array<string | number>, value: any }) => void
  @Action('editInputFlow') public editInputFlow!: (payload: { path: Array<string | number>, value: any }) => void

  private get quickRegistrationSteps() {
    return this.flowInputs?.quick_registration?.steps
  }

  public exitForward() {
    if (this.$route.name !== 'home') {
      this.$router.push({name: 'home'})
    }
    this.$popup.close()
    this.purgeFlow('quick_registration')
    this.getPersistent()
  }

  public getName(): string {
    return 'quick_registration'
  }

  public onFlowLoad() {
    this.preloadImages()
    this.handleClientToken()
  }

  public handleClientToken() {
    const c: string | null | undefined = this.flowOutputState?.quick_registration?.client_token
    const r = this.$route.query.client_token
    this.editField({
      path: ['quick_registration', 'client_token'],
      value: r || c || null,
    })
  }

  public preloadImages() {
    const imageBag: HTMLImageElement[] = []
    this.quickRegistrationSteps?.forEach((s) => {
      if (s.image) {
        const image = new Image()
        image.setAttribute('rel', 'preload')
        image.src = s.image
        imageBag.push(image)
      }
    })
    imageBag.length = 0
  }

  public created() {
    if (this.$isLogged()) {
      this.$router.replace('/')
    }
  }
}
