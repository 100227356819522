



















import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import AddCredit from '@/views/AddCredit.vue'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
  },
})
export default class InsufficientCredit extends Vue {
  @Prop({default: ''}) protected title!: string
  @Prop({default: ''}) protected subtitle!: string

  protected openAddCredit() {
    this.$dialog.close()
    this.$dialog.open(AddCredit, {props: {confirmText: this.$t('activities.book_and_shop.buy')}})
  }
}
