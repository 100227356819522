


















































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {
  VAutocomplete,
  VInput,
  VLayout,
  VListTileAction,
  VListTileActionText,
  VListTileContent,
  VListTileSubTitle,
  VListTileTitle,
  VTextField,
} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'
import {State} from 'vuex-class'
import {AppConfigState} from '@/store/modules/configuration'

import Countries, {Country} from '@/lib/kepler/countries'
import Utils from '@/utils'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {FlowInputField} from '@/lib/kepler/interfaces'

// for use with validator, very strict.
// import isMobilePhone from 'validator/es/lib/isMobilePhone'
// extend('mobilePhoneStrict', {
//   message(fieldName) {
//     return `${fieldName} is not a valid mobile phone number`
//   },
//   validate(value) {
//     return new Promise(resolve => {
//       resolve({valid: isMobilePhone(value.replace('-', ''))})
//     })
//   },
// })

interface Phone {
  prefix?: Country,
  number: string | number

  [key: string]: any
}

@Component({
  components: {
    FlowField: Utils.loadComponent('flow/FlowField'),
    ValidationProvider,
    VLayout,
    VTextField,
    VInput,
    VAutocomplete,
    VListTileContent,
    VListTileTitle,
    VListTileSubTitle,
    VListTileAction,
    VListTileActionText,
  },
  name: 'PhoneFieldWithValidation',
})
export default class PhoneFieldWithValidation extends Vue {
  @State('configuration') public configState!: AppConfigState
  @State('flowOutputs') public flowOutputState!: { [k: string]: any }
  @State('flowInputs') public flowInputs!: FlowInputsState

  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  @Prop() public context!: string

  public delimiter: string = '-'
  public countries: Country[] = Countries
  public phoneObject: Phone = {
    prefix: {
      name: '',
      dial_code: '',
      code: '',
      flag: '',
    },
    number: '',
  }

  // phone stuff
  protected get innerValue() {
    const p = this.phoneObject.prefix?.dial_code
    const n = this.phoneObject.number
    return p && n ? p + this.delimiter + n : null
  }

  protected get lengthClass() {
    if (this.phoneObject.prefix && this.phoneObject.prefix.dial_code) {
      return `length-${this.phoneObject.prefix.dial_code.replace('+', '').length}`
    }
  }

  protected get prefixHint() {
    return this.$isAvailable('phone.prefixHint.' + this.phoneObject.prefix?.code)
  }

  protected get verification() {
    const steps = this.flowInputs[this.context].steps

    const step = steps.find((s) => {
      return s.fields?.find((f) => {
        return f.type === 'phoneVerification'
      })
    }) || null

    const field: FlowInputField | null = step?.fields?.find((f) => {
      return f.type === 'phoneVerification' ? f : null
    }) || null

    return step && field ? {step, field} : null
  }

  @Watch('innerValue', {immediate: false})
  protected prefixChanged(phone: string | null) {
    this.$emit('input', phone)
  }

  @Watch('value', {immediate: false})
  protected valueChanged() {
    this.splitValue()
  }

  protected findPrefix(code: string, isNumber?: boolean) {
    const p = this.countries.filter((pfx) => {
      if (isNumber) {
        return pfx.dial_code === code
      }
      return pfx.code === code
    })[0]
    return p || this.phoneObject.prefix
  }

  protected prefixFilterFunction(item: Country, queryText: string) {
    return [
      item.name.toLowerCase(),
      item.code.toLowerCase(),
      item.dial_code,
    ].some((e) => e.includes(queryText.toLowerCase()))
  }

  protected created() {
    if (!this.phoneObject.prefix?.dial_code && !this.value) {
      if (Utils.getProp(this.configState as Record<string, any>, ['appConfig', 'default_country_code'])) {
        this.phoneObject.prefix = this.findPrefix(this.configState.appConfig.default_country_code)
      }
    } else if (!this.phoneObject.prefix?.dial_code && !!this.value) {
      this.splitValue()
    }
  }

  protected splitValue() {
    if (typeof this.value === 'object') {
      return
    }
    const phoneString = this.phoneObject.prefix?.dial_code + this.delimiter + this.phoneObject.number
    if (!!this.value && this.value.includes(this.delimiter) && this.value !== phoneString) {
      // value exists and includes delimiter and value is different from phoneString
      const split = this.value.split(this.delimiter)
      if (split.length > 1) {
        this.phoneObject.prefix = this.findPrefix(split[0], true)
        this.phoneObject.number = split[1]
      }
    }
  }
}
