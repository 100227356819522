








































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {Document, FieldConfigs} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

import AddDocumentView from '@/components/registration/RegistrationDrivingLicenseStep.vue'
import DateHelper from '@/lib/DateHelper'
import FlowDialog from '@/views/Flows/FlowDialog.vue'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import {FlowInputsState} from '@/store/modules/flowInputs'

@Component({
  name: 'Documents',
  components: {
    FilteredListWithAddButton: Utils.loadComponent('FilteredListWithAddButton'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Container: Utils.loadComponent('proxy/Container'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    CardGrid: Utils.loadComponent('CardGrid'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Ribbon: Utils.loadComponent('Ribbon'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Img: Utils.loadComponent('proxy/Image'),
  },
})
export default class Documents extends Vue {
  @State((state) => state.profile.documents) public documentsState!: Document[]
  @State('flowInputs') public flowInputs!: FlowInputsState

  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Action('getFlows') public getFlows!: () => Promise<FlowInputsState>
  @Action('getDocuments') protected getDocuments!: () => void
  @Prop({}) protected documents!: Document[]

  protected showActiveOnly: boolean = true
  protected loading: boolean = false

  protected get docs() {
    const d = this.documents ? this.documents : this.documentsState
    if (this.showActiveOnly) {
      return d.filter((doc) => {
        return doc.status === 'VALID'
      })
    }
    return d
  }

  protected get hasInactiveDocs() {
    return Object.keys(this.docs.filter((doc) => {
      return doc.status === 'VALID'
    })) !== Object.keys(this.docs)
  }

  protected get documentMessages() {
    const obj: { [id: string]: Array<{ label: string, value: string }> } = {}
    this.docs.forEach((doc) => {
      obj[doc.id] = []

      const fields = [
        {
          name: 'driver_license.number',
          cond: doc.number,
          string: {label: this.$t('profile.documents.document_number'), value: doc.number || ''},
        },
        {
          name: 'driver_license.issued_by',
          cond: doc.issuer.by,
          string: {label: this.$t('profile.documents.issued_by'), value: doc.issuer.by},
        },
        {
          name: 'driver_license.issued_date',
          cond: doc.issuer.date,
          string: {label: this.$t('profile.documents.issuing_date'), value: this.dateFormat(doc.issuer.date)},
        },
        {
          name: 'driver_license.expiry',
          cond: doc.expiry,
          string: {label: this.$t('profile.documents.expiration'), value: this.dateFormat(doc.expiry)},
        },
      ]

      const arr = obj[doc.id]

      fields.forEach((field) => {
        if (!this.fieldHidden(field.name) && field.cond) {
          arr.push(field.string)
        }
      })
    })

    return obj
  }

  protected created() {
    this.getDocuments()
    if (!this.docs.length && this.documentsState.length) {
      this.showActiveOnly = false
    }
  }

  protected fieldHidden(field: string) {
    if (!this.fieldConfigs) {
      return false
    }
    const f = Utils.getProp(this.fieldConfigs as Record<string, any>, field.split('.'))
    const fieldPropsString = f && typeof f === 'string' ? f : ''
    return fieldPropsString.includes('hidden')
  }

  @Watch('docs', {immediate: true})
  protected documentsPresence(val: Document[]) {
    this.$emit('noDocuments', !val.length)
  }

  protected dateFormat(date: string) {
    return DateHelper.formatDate(date, 'L')
  }

  protected addDocument() {
    const flowName = this.fieldConfigs?.document_flow

    if (flowName) {
      this.loading = true
      this.getFlows().then((r) => {
        if (r[flowName]) {
          if (r[flowName].dialog) {
            this.$dialog.open(FlowDialog, {props: {flowName}})
          } else {
            this.$popup.open(FlowAutoload, {props: {flowName}, hideTopbar: true})
          }
          this.loading = false
        }
      })
    } else {
      this.$popup.open(AddDocumentView, {title: this.$t('registration.documents')})
    }
  }
}
