import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {AxiosResponse} from 'axios'
import {RootState} from '@/store'
import Utils from '@/utils'

export interface FlowOutputRequest {
  endpoint: string
  payload: any
}

export class FlowOutputsState {
  [flowName: string]: { [fieldName: string]: any }
}

const mutations: MutationTree<FlowOutputsState> = {
  PURGE_FLOW_OUTPUTS(stateF) {
    Utils.flushObject(stateF)
  },
  PURGE_FLOW_OUTPUT(stateF, flowName?: string) {
    if (flowName) {
      delete stateF[flowName]
    } else {
      Utils.flushObject(stateF)
    }
  },
  PURGE_FLOW_OUTPUT_PROP(stateF, path: string[]) {
    Utils.deleteProp(stateF, path)
  },
  CREATE_FLOW_OUTPUT(stateF, flowName: string) {
    stateF[flowName] = {}
  },
  EDIT_FIELD(stateF, payload: {path: string[]; value: any}) {
    if (payload.path.length) {
      Utils.setProp(stateF, payload.path, payload.value)
    }
  },
}

const actions: ActionTree<FlowOutputsState, RootState> = {
  purge({commit}) {
    commit('PURGE_FLOW_OUTPUTS')
  },
  sendFlowOutput(store, req: FlowOutputRequest) {
    return sdk.flows.send(req.endpoint, req.payload)
      .then((r: AxiosResponse) => {
        if (req.endpoint.split('|').includes('REFRESH')) {
          store.dispatch('getFlows')
        }

        return r.data
      })
  },
  deleteFlowOutput({commit, dispatch}, flowName: string) {
    commit('PURGE_FLOW_OUTPUT', flowName)
    dispatch('sleep', flowName + '_purge')
  },
  deleteFlowOutputProp({commit, dispatch}, path: string[]) {
    commit('PURGE_FLOW_OUTPUT_PROP', path)
    dispatch('sleep', 'flow_delete_prop')
  },
  createFlowOutput({state, commit}, flowName: string) {
    commit('CREATE_FLOW_OUTPUT', flowName)
    return state[flowName]
  },
  editField({state, commit, dispatch}, payload: {path: string[]; value: any}) {
    const {path, value} = payload
    const str =  payload.path.join('.') + ':' + payload.value
    commit('EDIT_FIELD', payload)
    dispatch('sleep', `flow_edit-${str}`)
  },
}

export default {
  state: new FlowOutputsState(),
  mutations,
  actions,
}
