import {Component, Prop, Vue} from 'vue-property-decorator'
import {SubscriptionPlan} from '@/lib/kepler/interfaces'
import {VNodeData} from 'vue/types/vnode'
import {branding} from '@/lib/plugins/theme'

export default class VuetifyColorHelper {
  public static theme() {
    return branding.theme ? branding.theme : Vue.prototype.$branding.theme
  }

  public static color(c: string) {
    const t = this.theme()
    return t[c] !== undefined ? t[c] : c
  }

  public static getColorCSS(c: string) {
    const ele = document.createElement('div')
    ele.style.color = c
    return ele.style.color.split(/\s+/).join('').toLowerCase()
  }

  public static isCssColor(color?: string | null | false): boolean {
    return !!color && !!VuetifyColorHelper.getColorCSS(color)
  }

  public static setTextColor(color?: string | false, data: VNodeData = {}): VNodeData {
    if (this.isCssColor(color) && typeof data.style === 'object') {
      data.style = {
        ...data.style,
        'color': `${color}`,
        'caret-color': `${color}`,
      }
    } else if (color) {
      const [colorName, colorModifier] = color.toString().trim().split(' ', 2) as Array<string | undefined>
      data.class = {
        ...data.class,
        [colorName + '--text']: true,
      }
      if (colorModifier) {
        data.class['text--' + colorModifier] = true
      }
    }
    return data
  }

  public static setBackgroundColor(color?: string | false, data: VNodeData = {}): VNodeData {
    if (this.isCssColor(color) && typeof data.style === 'object') {
      data.style = {
        ...data.style,
        'background-color': `${color}`,
        'border-color': `${color}`,
      }
    } else if (color) {
      data.class = {
        ...data.class,
        [color]: true,
      }
    }
    return data
  }

  public static css(c: string) {
    return Object.keys(branding.customCss).includes(c) ? branding.customCss[c] : null
  }

  public static gradientAngle(c: string) {
    return Object.keys(branding.gradientAngle).includes(c) ? branding.gradientAngle[c] : null
  }

  public static classes(c: string) {
    return Object.keys(branding.customClasses).includes(c) ? branding.customClasses[c] : null
  }

  public static parseColor(color: string | number) {
    const memoize = function(factory: (col: any) => number[], ctx?: CanvasRenderingContext2D) {
      const cache: { [index: string]: number[] } = {}
      return function(key: string | number) {
        if (!(key in cache)) {
          cache[key] = factory.call(ctx, key)
        }
        return cache[key]
      }
    }

    const colorToRGBA = (function() {
      const canvas = document.createElement('canvas')
      canvas.width = canvas.height = 1
      const ctx = canvas.getContext('2d')!
      // invalid values of "col" are ignored
      return memoize(function(col) {
        ctx.clearRect(0, 0, 1, 1)
        ctx.fillStyle = col
        ctx.fillRect(0, 0, 1, 1)
        return [...ctx.getImageData(0, 0, 1, 1).data]
      })
    })()

    return {
      r: colorToRGBA(color)[0],
      g: colorToRGBA(color)[1],
      b: colorToRGBA(color)[2],
      a: colorToRGBA(color)[3],
    }
  }

  public static averageColors = (color1: string | number, color2: string | number) => {
    const c1 = VuetifyColorHelper.parseColor(color1)
    const c2 = VuetifyColorHelper.parseColor(color2)
    return {
      r: (c1.r + c2.r) / 2,
      g: (c1.g + c2.g) / 2,
      b: (c1.b + c2.b) / 2,
      a: (c1.a + c2.a) / 2,
    }
  }

  public static lightContrast = (color1: string | number, color2?: string | number) => {
    const rgb = color2 ? VuetifyColorHelper.averageColors(color1, color2) : VuetifyColorHelper.parseColor(color1)!
    const luminance = (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b)
    return (luminance > 167)
  }
}

@Component
export class CustomColorableMixin extends Vue {
  @Prop({type: String, default: null}) protected customCSS!: string | null
  @Prop({
    default: 180,
    type: Number,
  }) protected angle?: number | string
  @Prop({type: Boolean}) protected light?: boolean
  @Prop({type: Boolean}) protected dark?: boolean

  protected get customCss() {
    if (this.customCSS) {
      const c = VuetifyColorHelper.css(this.customCSS)
      if (c) {
        if (window.cordova.platformId === 'ios' && c['background-attachment']) {
          c['background-attachment'] = 'scroll' // Workaround for iOs
        }
        return c
      }
    }
    return null
  }

  protected get customStyle() {
    const arr: Array<Record<string, string>> = []
    if (this.customCss) {
      arr.push(this.customCss)
    }
    return arr // implemented in components
  }

  protected get customClasses() {
    return this.themeName ? VuetifyColorHelper.classes(this.themeName) : null
  }

  protected get themeName() {
    if (typeof this.customCSS === 'string') {
      return this.customCSS
    }
    return null
  }

  protected get contrastOverride(): boolean | null {
    if (this.themeName) {
      const c = VuetifyColorHelper.color(this.themeName + 'ContrastOverride')
      if (!c.includes('ContrastOverride')) {
        switch (c) {
          case 'light':
            return true
          case 'dark':
            return false
          default:
            return c ? VuetifyColorHelper.lightContrast(c) : null
        }
      }
    }
    return null
  }

  protected get isLight() {
    if (this.customClasses?.includes('theme--dark')) {
      return false
    }
    if (this.customClasses?.includes('theme--light')) {
      return true
    }
    if (this.contrastOverride !== null) {
      return this.contrastOverride
    }
    return !this.$vuetify.dark
  }

  protected color(c: string): string | null {
    if (VuetifyColorHelper.color(c) !== c) {
      return VuetifyColorHelper.color(c)
    } else if (VuetifyColorHelper.isCssColor(c)) {
      return c
    } else {
      return null
    }
  }
}

@Component
export class PlanColorMixin extends Vue {
  public getPlanColors(color: string | null, isDefault = false) {

    const hasDefault = (str: string) => VuetifyColorHelper.color('planDefault' + str) !== 'planDefault' + str

    const getColor = (str: string) => {
      if (isDefault && hasDefault(str)) {
        return VuetifyColorHelper.color('planDefault' + str)
      } else {
        return VuetifyColorHelper.color('plan' + str)
      }
    }
    const obj = {
      top: getColor('Top'),
      bottom: getColor('Bottom'),
    }
    if (color) {
      const c = color.split('|')
      obj.top = c[0]
      obj.bottom = c[1] ? c[1] : c[0]
    }
    return obj
  }

  public getPlanIsLight(plan: SubscriptionPlan, isDefault = false) {
    const colors = this.getPlanColors(plan.color, isDefault)
    return VuetifyColorHelper.lightContrast(colors.top, colors.bottom)
  }

  public getPlanCSS(isDefault: boolean) {
    return isDefault ? 'planDefault' : 'plan'
  }
}

export function getTrueBgColor(el: Element): string {
  const TRANSPARENT_COLORS = ['rgba(0, 0, 0, 0)', 'transparent']

  function getTopElement(): Element | null {
    try {
      return window.top?.document.documentElement || null
    } catch (e) {
      return null
    }
  }

  function getBackgroundColor(elem: Element): string {
    return window.getComputedStyle(elem).getPropertyValue('background-color')
  }

  let elem: Element | null = el
  const topElement = getTopElement()

  while (elem && elem instanceof Element) {
    const cbg = getBackgroundColor(elem)
    if (cbg && !TRANSPARENT_COLORS.includes(cbg)) {
      return cbg
    }
    if (elem === topElement) {
      return 'initial'
    }
    elem = elem.parentElement
  }
  return ''
}
