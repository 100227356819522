












import {Vue, Component} from 'vue-property-decorator'
import {Getter, State} from 'vuex-class'
import {Popup} from '@/lib/kepler/interfaces'
import {Language} from '@/lib/plugins/lang'
import {DialogState} from '@/store/modules/dialogs'
import CloseButton from '@/components/CloseButton.vue'

@Component({
  components: {CloseButton},
})
export default class FullScreenPopup extends Vue {
  @State('dialogState') public dialogState!: typeof DialogState

  @Getter('fullscreenPopup') public fullscreenPopup?: Popup
  @Getter('currentLanguage') public currentLanguage!: Language
  @Getter('dialogIsOpen') public dialogIsOpen!: boolean
}
