




import {Vue, Component} from 'vue-property-decorator'

@Component({
  name: 'HelpButton',
})
export default class HelpButton extends Vue {
}
