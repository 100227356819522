
































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action, Getter} from 'vuex-class'
import CloseButton from '../components/CloseButton.vue'

import Button from '@/components/Button.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import SelectTag from '@/components/proxy/Inputs/SelectTag.vue'
import Card from '@/components/proxy/Card/Card.vue'
import {Language} from '@/lib/plugins/lang'

@Component({
  components: {
    Card,
    SelectTag,
    CustomIcon,
    Button,
    CloseButton,
  },
  name: 'ChangeLangDialog',
})
export default class ChangeLangDialog extends Vue {
  @Action('setLang') public setLang!: any
  @Getter('currentLanguage') public currentLanguage?: Language
  @Prop({type: Boolean, default: false}) public embedded!: boolean

  public lang: string = ''
  public tempLang: string = ''

  public get confirmButton() {
    return this.$t('action.proceed')
  }

  protected get available() {
    return this.$langAvailable()
  }

  public confirm() {
    this.setLang(this.tempLang)
    this.$langLoader(this.tempLang)
    this.$vuetify.rtl = ['ar'].includes(this.tempLang)
    this.$dialog.close()
  }

  public changeLang(lang: string) {
    this.tempLang = this.$flagToLang(lang)
  }

  public changeLangImmediate(lang: string) {
    const l = this.$flagToLang(lang)
    this.setLang(l)
    this.$langLoader(l)
    this.$vuetify.rtl = ['ar'].includes(l)
  }

  protected created() {
    this.lang = this.$langToFlag(this.currentLanguage || this.$currentLang())
    this.changeLang(this.lang)
  }
}
