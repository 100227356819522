import {ActionTree, GetterTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {InterventionParkingLot, InterventionRequest, InterventionRequestStatus, VehicleType} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'

type InterventionRequestPageData = Partial<Record<InterventionRequestStatus, InterventionRequest[]>>

export class MaintenanceState {
  public lots: InterventionParkingLot[] = []
  public lot: InterventionParkingLot | null = null
  public zones: string[] = []
  public zone: string | null = null
  public vehicleType: keyof typeof VehicleType | null = null
  public response: InterventionRequestPageData | null = null
}

const mutations  = {
  SET_MAINTENANCE_RESPONSE(m, payload: InterventionRequestPageData | null) {
    m.response = payload
  },
  SET_MAINTENANCE_ZONES(m, payload) {
    m.zones = payload
  },
  SET_MAINTENANCE_ZONE(m, payload) {
    m.zone = payload
  },
  SET_MAINTENANCE_LOTS(m, payload) {
    m.lots = payload
  },
  SET_MAINTENANCE_LOT(m, payload) {
    m.lot = payload
  },
  SET_VEHICLE_TYPE(m, payload) {
    m.vehicleType = payload
  },
} as MutationTree<MaintenanceState>

const actions: ActionTree<MaintenanceState, RootState> = {
  init({commit, dispatch, rootGetters}) {
    commit('SET_MAINTENANCE_RESPONSE', null)
    if (rootGetters.maintenance) {
      dispatch('getMaintenanceZones')
    }
  },
  async getInterventionRequests({commit, getters}) {
    const r = await sdk.maintenance.get(getters.maintenanceReq)
    const response: InterventionRequestPageData = {}
    r.data.forEach((ir) => {
      const s = ir.status ?? 'AUTOMATIC'
      const rr = response[s]
      if (Array.isArray(rr)) {
        rr.push(ir)
      } else {
        response[s] = [ir]
      }
    })
    commit('SET_MAINTENANCE_RESPONSE', response)
  },
  async getMaintenanceLots({commit, dispatch, rootGetters}) {
    const {data} = await sdk.maintenance.parkingLots({
      latitude: rootGetters.userPosition.lat,
      longitude: rootGetters.userPosition.lng,
    })
    commit('SET_MAINTENANCE_LOTS', data)
    if (data.length === 1) {
      dispatch('setMaintenanceLot', data[0])
    }
  },
  setMaintenanceLot({commit}, payload: InterventionParkingLot | null) {
    commit('SET_MAINTENANCE_LOT', payload)
  },
  async getMaintenanceZones({commit, dispatch}) {
    const {data} = await sdk.maintenance.zones()
    commit('SET_MAINTENANCE_ZONES', data)
    if (data.length === 1) {
      dispatch('setMaintenanceZone', data[0])
    }
  },
  setMaintenanceZone({commit}, payload: string | null) {
    commit('SET_MAINTENANCE_ZONE', payload)
  },
  setVehicleType({commit}, payload: keyof typeof VehicleType | null) {
    commit('SET_VEHICLE_TYPE', payload)
  },
}

export default {
  state: new MaintenanceState(),
  mutations,
  actions,
  getters: {
    maintenanceReq: ({zone, lot, vehicleType}, g, rs, {userPosition}) => {
      const {latitude, longitude} = userPosition || {latitude: 0, longitude: 0}
      return {
        latitude,
        longitude,
        parking_lot_id: lot?.parking_lot.id,
        zone_name: zone || undefined,
        vehicle_type: vehicleType || undefined,
      }
    },
  } as GetterTree<MaintenanceState, RootState>,
}
