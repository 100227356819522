
























import {Vue, Component} from 'vue-property-decorator'
import sdk from '@/lib/kepler/sdk'
import {Action, Getter, State} from 'vuex-class'
import Utils from '@/utils'
import {FieldConfigs, SubscriptionPlan} from '@/lib/kepler/interfaces'
import AddClient from '@/components/AddClient.vue'

interface Activity {
  name: string
  title: string
  description?: string
  color?: string
  icon?: string
  route?: string
  action?: string
}

@Component({
  components: {
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    BookingActivities: Utils.loadView('BookingActivities'),
    Container: Utils.loadComponent('proxy/Container'),
    CardList: Utils.loadComponent('CardList'),
    Img: Utils.loadComponent('proxy/Image'),
  },
})

export default class Activities extends Vue {
  @State((state) => state.subscriptions.hasQueriedPlans) public hasQueriedPlans!: boolean | null
  @State((state) => state.profile?.driver?.profile_picture) public profilePicture!: boolean

  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Getter('availablePlans') public plans!: SubscriptionPlan[] | null
  @Getter('mainDriver') public isMain!: boolean

  @Action('getPlans') public getPlans!: () => Promise<SubscriptionPlan[]>

  protected loading: boolean = false

  protected get hiddenList(): string[] {
    if (this.fieldConfigs?.activities_hidden_sections) {
      return this.fieldConfigs?.activities_hidden_sections.toLowerCase().split(',')
    }
    return []
  }

  protected get activitiesList(): Activity[] {
    const logged: Activity[] = [
      {
        name: 'profile',
        title: 'activities.profile.title',
        description: 'activities.profile.description',
        color: 'accent',
        icon: 'mdi-account',
        route: 'profile',
      },
      {
        name: 'history',
        title: 'activities.history.title',
        description: 'activities.history.description',
        color: 'accent',
        icon: 'mdi-calendar-clock',
        route: 'history',
      },
    ]
    if (this.fieldConfigs?.invite_friend_enabled) {
      logged.push({
        name: 'invite',
        title: 'activities.invite.title',
        description: 'activities.invite.description',
        color: 'accent',
        icon: 'mdi-account-plus',
        action: 'invite',
      })
    }
    const notLogged: Activity[] = [
      {
        name: 'register',
        title: 'activities.register.title',
        description: 'activities.register.description',
        color: 'accent',
        icon: 'mdi-account-plus',
        route: 'registration',
      },
      {
        name: 'login',
        title: 'activities.login.title',
        description: 'activities.login.description',
        color: 'primary',
        icon: 'mdi-account',
        route: 'login',
      },
    ]

    if (this.hasPlans) {
      notLogged.unshift({
        name: 'plans',
        title: 'activities.plans_not_logged.title',
        description: 'activities.plans_not_logged.description',
        color: 'accent',
        icon: 'mdi-text-box-multiple-outline',
        route: 'plans',
      })
    }

    const result: Activity[] = this.isLogged ? logged : notLogged

    return result.filter((a) => !this.hiddenList.includes(a.name))
  }

  protected get isLogged() {
    return sdk.people.isLogged()
  }

  protected get otherActivitiesHeader() {
    if (!this.activitiesList.length) {
      return null
    }
    const t = this.isLogged ? 'activities.other_activities.title' : 'activities.not_logged.title'
    const d = this.isLogged ? 'activities.other_activities.description' : 'activities.not_logged.description'
    const obj = {
      title: this.$isAvailable(t) || '',
      subtitle: this.$isAvailable(d) || '',
    }
    return obj.title || obj.subtitle ? obj : null
  }

  protected get hasPlans() {
    return this.hasQueriedPlans ? !!this.plans?.length : null
  }

  protected activityAction(a: Activity) {
    if (a.route) {
      this.$router.push({name: a.route})
    }
    if (a.action) {
      switch (a.action) {
        case 'invite':
          this.$dialog.open(AddClient)
          break
        default:
          return
      }
    }
  }

  protected created() {
    if (this.hasPlans === null) {
      this.loading = true
      this.getPlans().finally(() => {
        this.loading = false
      })
    }
  }
}
