










































import {Component, Prop, VModel} from 'vue-property-decorator'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import {
  VAvatar,
  VCard,
  VCheckbox,
  VDivider,
  VIcon, VImg,
  VListTile,
  VListTileAction,
  VListTileAvatar,
  VListTileContent,
  VListTileSubTitle,
  VListTileTitle,
} from 'vuetify/lib'
import ColorCard from '@/components/ColorCard.vue'

@Component({
  components: {
    VAvatar,
    VCard,
    VCheckbox,
    VListTile,
    VListTileAvatar,
    VIcon,
    VListTileContent,
    VListTileTitle,
    VListTileSubTitle,
    VListTileAction,
    VDivider,
    VImg,
  },
})
export default class CardList extends ColorCard {

  @Prop({
    type: String,
    default: 'transparent',
  }) public circleColor!: string
  @Prop({}) public icon?: string
  @Prop({}) public iconColor?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public tile?: boolean
  @Prop({
    type: Boolean,
    default: true,
  }) declare public ripple: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public divider?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public ellipsis?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public check!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public radio!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public fullwidth!: boolean
  @Prop({type: String}) public action?: string
  @Prop({type: String}) public description?: string
  @Prop({type: String}) public image?: string

  @VModel({type: Boolean}) public checked!: boolean

  public get checkIsLight() {
    if (this.circleColor !== 'transparent') {
      return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(this.circleColor))
    }
    return !this.$vuetify.dark
  }

  public clickAction($event: Event) {
    if (this.check) {
      this.checked = !this.checked
      // this.$emit('input', !this.checked)
    } else {
      this.$emit('click', $event)
    }
  }
}
