










import {Component} from 'vue-property-decorator'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import Flow from '@/components/flow/Flow.vue'
import {FlowInputStep} from '@/lib/kepler/interfaces'
import {mixins} from 'vue-class-component'
import {FlowMixin} from '@/components/flow/FlowMixin'

@Component({
  components: {
    Flow,
  },
})
export default class BaseFlow extends mixins<FlowMixin>(FlowMixin) {

  public initialLoading: string | null = null

  public steps: FlowInputStep[] = []

  public getName() {
    return this.$attrs.flowName || 'base_flow'
  }

  public get flow() {
    return this.flowInputs[this.getName()]
  }

  public exitBack() {
    if (this.flow?.warn_on_close === undefined || this.flow.warn_on_close) {
      this.$dialog.open(ConfirmDialogCallback, {
        props: {
          code: '',
          title: this.$isAvailable('flows.exit_back.title'),
          subtitle: this.$t('flows.exit_back.subtitle'),
          confirmText: this.$isAvailable('flows.exit_back.confirm'),
          cancelText: this.$isAvailable('flows.exit_back.cancel'),
          confirmColor: 'buttonDefaultColor',
          imageState: '',
          showCloseButton: false,
          confirmCallback: () => {
            this.getPersistent()
            if (this.$route.name !== 'home') {
              this.$router.push({name: 'home'})
            }
            this.$popup.close()
          },
          cancelCallback: () => {
            return
          },
        },
      })
    } else {
      this.getPersistent()
      if (this.$route.name !== 'home') {
        this.$router.push({name: 'home'})
      }
      this.$popup.close()
    }
  }

  public exitForward() {
    this.onConfirmEnd()
    this.purgeFlow(this.getName())
    this.getPersistent()
  }

  public onConfirmEnd() {
    //
  }

  public onFlowLoad() {
    //
  }
}
