






















































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BookingRequest} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'
import {Action} from 'vuex-class'
import moment from 'moment'

@Component({
  components: {
    Chip: Utils.loadComponent('proxy/Chip'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardList: Utils.loadComponent('CardList'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
})
export default class BookingRequestCard extends Vue {
  @Prop() protected request!: BookingRequest
  @Action('deleteBookingRequest') protected deleteBookingRequest!: (id: string) => Promise<void>

  protected isHiding = false

  protected get time() {
    return DateHelper.formatTimeOffset(this, 'dhm', this.request.end_timestamp - this.request.start_timestamp)
  }

  protected get statusColor() {
    const s = this.request.status
    switch (s) {
      case 'ACCEPTED':
      case 'AUTO_ACCEPTED':
      case 'COMPLETED':
        return 'success'
      case 'REJECTED':
      case 'VOIDED':
      case 'PENDING_ALLOCATION':
        return 'error'
      case 'NEW':
      case 'WAITING':
      case 'AUTO_WAITING':
      case 'AUTO_WAITING_OWFF':
      case 'PENDING_CONFIRMATION':
      default:
        return 'warning'
    }
  }

  protected get carSoon() {
    return this.request.status.endsWith('ACCEPTED') && !this.request.reservation ?
      this.$t('booking.request_car_soon') : null
  }

  protected getDate(isStart: boolean) {
    const start = this.request.start
    const end = this.request.end

    if (start && end) {
      return moment(isStart ? start : end).format(this.$datetimeformat())
    }
  }

  protected hideRequest() {
    this.isHiding = true
    this.deleteBookingRequest(this.request.id).finally(() => {
      this.isHiding = false
    })
  }

}
