import {Vue, Component, Watch} from 'vue-property-decorator'

@Component
export default class TabHistoryMixin extends Vue {
  public currentTab: number | null = null
  public tabMap: { [key: string]: number } = {}

  public mounted() {
    this.onHashChange(this.$route.hash)
  }

  @Watch('currentTab', {immediate: true})
  private onTabChanged(n: number) {
    if (this.currentTab !== null) {
      for (const hash in this.tabMap) {
        if (this.tabMap.hasOwnProperty(hash) && this.tabMap[hash] === this.currentTab && hash) {
          this.$router.replace(this.$route.path + hash).catch((err) => {
            if (err && err.name !== 'NavigationDuplicated') {
              console.error(err)
            }
          })
        }
      }
    }
  }

  @Watch('$route.hash', {immediate: true})
  private onHashChange(hash: string) {
    this.currentTab = this.tabMap[hash] ? this.tabMap[hash] : 0
  }
}
