



















import {Vue, Component} from 'vue-property-decorator'
import Card from './proxy/Card/Card.vue'
import Container from './proxy/Container.vue'
import Layout from './proxy/Layout.vue'
import Flex from './proxy/Flex.vue'
import Badge from './proxy/Badge.vue'
import Img from './proxy/Image.vue'
import ProgressCircular from './proxy/ProgressCircular.vue'
import Button from './Button.vue'
import UploadTypeChooser from './UploadTypeChooser.vue'
import Icon from './proxy/Icon.vue'

@Component({
  components: {Icon, UploadTypeChooser, Button, ProgressCircular, Img, Badge, Flex, Layout, Container, Card},
  name: 'AttachmentsLoader',
})
export default class AttachmentsLoader extends Vue {
  protected attachments: any[] = []
  protected uploadOpen: boolean = false

  protected prepareAttachment() {
    this.uploadOpen = false
    this.attachments.push('')
  }

  protected openUploadChooser() {
    this.uploadOpen = true
  }

  protected abortUpload() {
    this.attachments.splice(-1, 1)
  }

  protected toggleUploadChooser() {
    this.uploadOpen = !this.uploadOpen
  }

  protected addImage(data: any) {
    this.attachments.splice(-1, 1, data.url)
    this.$emit('addAttachment', false, data)
  }

  protected removeImage(index: number) {
    this.attachments.splice(index, 1)
    this.$emit('removeAttachment', index)
  }
}
