























import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Action, Getter, State} from 'vuex-class'
import InfiniteScrollHandler, {infiniteHandlerState} from '../../lib/InfiniteScrollHandler'
import {TopbarButton} from '@/store/modules/topbar'
import {PaginatedRequest, ReservationPaginatedResponse, ReservationResponse} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import AddDriver from '../../components/AddDriver.vue'
import HistoryBookingDetail from './HistoryBookingDetail.vue'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Button: Utils.loadComponent('Button'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    EmptyList: Utils.loadComponent('EmptyList'),
    CardReservation: Utils.loadComponent('entities/reservation/CardReservation'),
    InfiniteLoading: Utils.loadComponent('infiniteScroll/InfiniteLoading'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
  mixins: [InfiniteScrollHandler],
})
export default class MyDriversListHistory extends mixins<InfiniteScrollHandler>(InfiniteScrollHandler) {
  @State((state) => state.booking.driversReservations) public driversReservations!: ReservationPaginatedResponse
  @Getter('hasDrivers') public hasDrivers!: boolean

  @Action('setActionButtons') public setActionButtons!: (buttons: TopbarButton[]) => void
  @Action('myDriversReservations') public myDriversReservations!: (req?: PaginatedRequest) => Promise<ReservationResponse>
  @Action('flushAndReloadDriversReservationsHistory') public flushAndReload!: () => Promise<ReservationPaginatedResponse>

  protected loading: boolean = true
  protected reservations: ReservationResponse[] = []
  protected max: number = Infinity

  protected mounted() {
    if (this.driversReservations.current_page < 0) {
      this.flushAndReload().finally(() => {
        this.loading = false
      })
    } else {
      this.loading = false
    }

    const buttons: TopbarButton[] = [
      {
        icon: 'mdi-refresh',
        action: () => {
          this.reloadHistory()
        },
      },
    ]
    this.setActionButtons(buttons)
  }

  protected infiniteHandler(state: infiniteHandlerState) {
    if (this.driversReservations.current_page >= 0) {
      this.handler(state, this.max, this.driversReservations, this.myDriversReservations)
    }
  }

  protected openDetail(res: ReservationResponse) {
    this.$popup.open(HistoryBookingDetail, {
      props: {resNum: res.number, res},
      title: this.$t('booking.reservation', {number: res.number}),
    })
  }

  protected inviteDriver() {
    this.$dialog.open(AddDriver)
  }

  protected reloadHistory() {
    this.loading = true
    const infinite = (this.$refs.infiniteLoader as any)
    infinite.isLoading = true
    this.flushAndReload().then(() => {
      this.loading = false
      infinite.isLoading = false
    })
  }
}
