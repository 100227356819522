
















import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {
  AvailabilityRequest,
  AvailabilityResponse,
  ExtraActionRequest,
  ExtraActionResponse,
  ReservationEditRequest,
  ReservationResponse,
} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

import QuickExtendDialog from '@/components/QuickExtendDialog.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import BookingEdit from '@/views/Booking/BookingEdit.vue'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import VehicleAddReport from '@/views/Vehicle/VehicleAddReport.vue'
import FuelPinDialog from '@/views/FuelPinDialog.vue'
import ReservationHelper from '@/lib/reservation'
import RechargeDialog from '@/views/RechargeDialog.vue'
import ColorCard from '@/components/ColorCard.vue'
import Container from '@/components/proxy/Container.vue'
import moment from 'moment'

interface BookingActionButton {
  operation: string
  clickAction: () => void,
  text: string
}

@Component({
  components: {
    Container,
    ColorCard,
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'BookingActions',
})

export default class BookingActions extends Vue {
  @State((state) => state.configuration.appConfig.parking_report_id) public parkingReportId!: string | null
  @Action('editReservation') public editReservation!: (p: ReservationEditRequest) => Promise<ReservationResponse>
  @Action('deleteReservation') public deleteReservation!: (p: ReservationResponse) => Promise<void>
  @Action('vehicleAvailability') public vehicleAvailability!: (p: AvailabilityRequest) => Promise<AvailabilityResponse[]>
  @Action('sendExtraAction') public sendExtraAction!: (p: ExtraActionRequest) => Promise<ExtraActionResponse>
  @Action('current') public current!: () => Promise<ReservationResponse[]>
  @Action('futureReservations') public futureReservations!: (force?: boolean) => Promise<void>

  @Prop({type: Object, required: true}) public readonly reservation!: ReservationResponse

  public loading: boolean = false
  public availability: AvailabilityResponse[] = []

  protected get bookingActions() {
    return this.reservation.extra?.actions || null
  }

  protected get buttons() {
    const arr: BookingActionButton[] = []
    const list: Record<string, () => void> = {
      extend: this.extendAction,
      edit: this.edit,
      cancel: this.cancelReservation,
      refuel: this.getFuelPin,
      charge_start: this.recharge('start'),
      report_unavailable_parking: this.reportParkUnavailable,
    }

    const textString = (action: string): string => {
      const text = this.$isAvailable('booking.action.' + action) || null
      const nestedText = this.$isAvailable('booking.action.' + action + '.action') || null
      return text || nestedText || action
    }

    this.bookingActions?.forEach((operation) => {
      const extraAction = () => this.sendExtra(operation)
      const text = textString(operation)
      const clickAction = !!list[operation] ? list[operation] : extraAction
      arr.push({operation, text, clickAction})
    })
    return arr
  }

  protected get vehicleTypeColor() {
    const vs = this.reservation.vehicle_slot
    return `${this.reservation.type}${vs.vehicle.category.type}`.toUpperCase()
  }

  // actions
  protected getFuelPin() {
    let text = this.$t('vehicle.refuel.not_necessary')
    let callback: (() => void) | undefined
    if (this.reservation.vehicle_slot.vehicle.fuel_level < 30) {
      text = this.$t('vehicle.refuel.necessary')
      callback = () => {
        this.$dialog.close()
        this.$popup.open(VehicleAddReport, {
          props: {
            vehicleSlot: this.reservation.vehicle_slot,
            vehicle: this.reservation.vehicle_slot.vehicle,
          }, title: this.$t('vehicle.report.diary_report'),
        })
      }
    }

    this.loading = true
    this.$store.dispatch('getFuelPin', this.reservation.number).then(
      (r) => {
        this.$dialog.open(FuelPinDialog, {
          props: {
            imageState: 'success.svg',
            confirmText: this.$t('action.close'),
            pin: r.fuel_card_pin,
            odometer: r.vehicle_odometer,
            callback,
            data: text,
            singleAction: true,
            emitConfirm: false,
          },
        })
      },
    ).finally(() => {
      this.loading = false
    })
  }

  protected buttonColor(operation: string) {
    return operation === 'charge_start' ? 'EVCHARGE' : this.vehicleTypeColor
  }

  protected recharge(s: 'start' | 'end'): () => void {
    switch (s) {
      case 'start':
        return () => {
          this.$dialog.open(RechargeDialog, {
            props: {
              callback: (port: string) => {
                return this.sendExtraAction({
                  reservationNumber: this.reservation.number,
                  operation: 'charge_start',
                  data: {port},
                })
              },
            },
          })
        }
      case 'end':
        return () => {
          //
        }
    }

  }

  protected reportParkUnavailable() {
    this.$popup.open(VehicleAddReport, {
      props: {
        vehicleSlot: this.reservation.vehicle_slot,
        vehicle: this.reservation.vehicle_slot.vehicle,
        reservation: this.reservation,
        reportType: this.parkingReportId,
        description: this.$t('vehicle.report.park_occupied'),
      }, title: this.$t('vehicle.report.diary_report'),
    })
  }

  protected extendAction() {
    this.loading = true
    this.getAvailability()
      .then(this.openExtend)
      .finally(() => {
        this.loading = false
      })
  }

  protected getAvailability(): Promise<void> {
    const reservation = this.reservation
    if (reservation.end) {
      return this.vehicleAvailability({
        vehicle_id: reservation.vehicle_slot.vehicle.id,
        start: reservation.end,
        end: moment(reservation.end, 'YYYY-MM-DD HH:mm:ss')
          .add(2, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
      }).then((availability: AvailabilityResponse[]) => {
        availability[0].selected = true
        this.availability = availability
      })
    }
    return Promise.reject('no start or end dates in selected reservation')
  }

  protected sendExtra(operation: string, data?: Record<string, any>) {
    const reservationNumber = this.reservation.number
    this.loading = true
    this.sendExtraAction({reservationNumber, operation, data}).then((r) => {
      if (r.result) {
        this.$dialog.open(ConfirmDialog, {
          props: {
            showCloseButton: true,
            imageState: '',
            code: '',
            title: r.operation,
            subtitle: r.result,
            singleAction: true,
          },
        })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  protected edit() {
    const reservation = this.reservation
    this.$dialog.open(BookingEdit, {
      props: {
        reservation, confirmCallback: (editRequest: ReservationEditRequest) => {
          this.loading = true
          this.editReservation(editRequest)
            .then((r: ReservationResponse) => {
              reservation.start = r.start
              reservation.end = r.end
              const isFuture = ReservationHelper.isFuture(r)
              const isCurrent = ReservationHelper.isCurrent(r)
              if (isFuture) {
                this.futureReservations(true).then(this.confirmEdit)
              } else if (isCurrent) {
                this.current().then(this.confirmEdit)
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      },
    })
  }

  protected confirmEdit() {
    this.$dialog.close()
    this.$dialog.open(ConfirmDialog, {
      props: {
        imageState: 'success.svg',
        confirmText: this.$t('common.ok'),
        code: '',
        subtitle: '',
        title: this.$t('booking.action.edit_confirm'),
        singleAction: true,
        emitConfirm: false,
      },
    })
  }

  protected openExtend() {
    this.$dialog.open(QuickExtendDialog, {
      props: {
        reservation: this.reservation,
        availability: this.availability,
        onConfirm: () => this.current().then(this.confirmEdit),
      },
    })
  }

  protected cancelReservation() {
    this.$dialog.open(ConfirmDialogCallback, {
      props: {
        code: '',
        title: this.$t('booking.action.cancel_confirm'),
        subtitle: '',
        confirmText: this.$t('booking.action.cancel'),
        cancelText: this.$t('booking.action.keep'),
        confirmColor: 'error',
        imageState: 'warn.svg',
        confirmCallback: () => {
          this.loading = true
          this.deleteReservation(this.reservation).then(() => {
            this.$popup.close()
          }).finally(() => {
            this.loading = false
          })
        },
        cancelCallback: () => {
          return 1
        },
      },
    })
  }
}
