

















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import {Action, State} from 'vuex-class'
import {BookingRequestEstimateRequest, Estimate, FlowInputStep, SubscriptionPlan} from '@/lib/kepler/interfaces'
import {debounce} from '@/lib/Debounce'
import DateHelper from '@/lib/DateHelper'
import sdk from '@/lib/kepler/sdk'
import moment from 'moment'

@Component({
  components: {
    Icon: Utils.loadComponent('proxy/Icon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    CardPlan: Utils.loadComponent('subscriptions/CardPlan'),
    FlowField: Utils.loadComponent('flow/FlowField'),
  },
})
export default class BookingRequestEstimate extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }
  @Action('getPlans') public getPlans!: () => Promise<SubscriptionPlan[]>
  @Prop({required: true}) public step?: FlowInputStep
  @Prop({
    type: String,
    required: false,
  }) public name?: string
  @Prop({
    type: String,
    required: false,
  }) public description?: string

  public loading: boolean = false
  public estimate: Estimate | null = null

  @Watch('requestData', {deep: true, immediate: true})
  protected onRequestDataChanged(req: typeof this.requestData): void {
    if (req) {
      this.loading = true
      debounce(() => {
        sdk.booking.requestEstimate(req).then(({data}) => {
          this.estimate = data
        }).finally(() => {
          this.loading = false
        })

      }, 1000, {isImmediate: true})()
    }
  }

  protected get time() {
    const {start, end} = this.requestData || {}
    if (start && end) {
      const diff = moment(end, 'YYYY-MM-DD HH:mm:ss').unix() - moment(start, 'YYYY-MM-DD HH:mm:ss').unix()
      return DateHelper.formatTimeOffset(this, 'dhm', diff)
    }
  }

  protected get cost() {
    const {time_cost, distance_cost} = this.estimate || {}
    if (time_cost || distance_cost) {
      return this.$currency((time_cost ?? 0) + (distance_cost ?? 0))
    }
    return 0
  }

  protected get requestData(): BookingRequestEstimateRequest | null {
    const flow = this.flowOutputs[this.step?.context || 'driver_booking_request']
    if (flow && 'dates' in flow && 'special_vehicle' in flow && 'estimated_distance' in flow) {
      const {special_vehicle, estimated_distance} = flow
      const {start, end} = flow.dates
      if (start && end) {
        return {start, end, special_vehicle, estimated_distance}
      }
    }
    return null
  }
}
