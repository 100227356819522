import {ActionTree, GetterTree, MutationTree} from 'vuex'
import {RootState} from '@/store'
import storage from '@/lib/storage'
import Vue from 'vue'
import sdk from '@/lib/kepler/sdk'

export interface Account {
  name: string
  surname: string
  username: string
  email: string
  client_type: string
  organization_name: string
  picture: string
  token: string
}

export type AccountsState = Record<string, Account>

const mutations: MutationTree<AccountsState> = {
  PURGE_ACCOUNTS(state) {
    state = {}
    storage.delete('rootAccounts')
  },
  INIT_ACCOUNTS(PState) {
    if (storage.check('rootAccounts')) {
      storage.restore('rootAccounts', PState)
    } else {
      storage.save('rootAccounts', PState)
    }
  },
  SAVE_ACCOUNT(PState, payload: Account & { id: string }) {
    Vue.set(PState, payload.id, payload)
    storage.save('rootAccounts', PState)
  },
  REMOVE_ACCOUNT(PState, id: string) {
    delete PState[id]
    storage.save('rootAccounts', PState)
  },
}

const actions: ActionTree<AccountsState, RootState> = {
  init({commit}) {
    commit('INIT_ACCOUNTS')
  },
  purgeAccounts({commit}) {
    commit('PURGE_ACCOUNTS')
  },
  saveAccount({rootState, commit, dispatch}, payload?: Account & { id: string }) {
    return new Promise<void>((resolve, reject) => {
      sdk.people.driver().then((r_d) => {
        sdk.people.client().then((r_c) => {
          const d = r_d.data
          const c = r_c.data

          if (!payload && d && c) {
            commit('SAVE_ACCOUNT', {
              id: d.id,
              name: d.name,
              surname: d.surname,
              username: d.username,
              email: d.email,
              picture: d.profile_picture,
              organization_name: c.organization_name,
              token: rootState.userToken,
              client_type: c.client_type.code,
            })
          } else if (payload) {
            commit('SAVE_ACCOUNT', payload)
          } else {
            throw new Error('missing payload')
          }
          resolve()
        })
      }).catch(reject)
    })
  },
  removeAccount({commit}, id: string) {
    return new Promise<void>((resolve) => {
      commit('REMOVE_ACCOUNT', id)
      resolve()
    })
  },
  switchAccount({dispatch, state}, id: string) {
    return new Promise<void>((resolve, reject) => {
      dispatch('getDriver').then(() => {
        const account = state[id]
        if (id && account) {
          dispatch('saveAccount').then(() => {
            dispatch('removeAccount', id).then(() => {
              dispatch('switchProfile', account.token).then(() => {
                resolve()
              })
            })
          })
        }
      }).catch(reject)
    })
  },
}

const getters: GetterTree<AccountsState, RootState> = {
  accounts: (s: AccountsState) => s,
  hasSavedAccounts: (s: AccountsState) => !!Object.keys(s).length,
}

export default {
  state: {} as AccountsState,
  mutations,
  actions,
  getters,
}
